/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Satellite API
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
export interface IntegrationTo {
  enabled: boolean;
  email: string;
}

export type GeoJsonCoordinatesItem = { [key: string]: unknown };

export interface GeoJson {
  type: GeoJsonType;
  coordinates: GeoJsonCoordinatesItem[];
}

export interface GeoJsonMultiPolygon {
  type: GeoJsonType;
  coordinates: number[][][][];
  geometries: GeoJson[];
}

export type GeoJsonType = typeof GeoJsonType[keyof typeof GeoJsonType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GeoJsonType = {
  Point: 'Point',
  MultiPoint: 'MultiPoint',
  LinearRing: 'LinearRing',
  LineString: 'LineString',
  MultiLineString: 'MultiLineString',
  Polygon: 'Polygon',
  MultiPolygon: 'MultiPolygon',
  GeometryCollection: 'GeometryCollection',
} as const;

export interface IndexResponse {
  type?: VegetationIndex;
  dateFrom?: string;
  dateTo?: string;
  chemicalElement?: string;
  crop?: SatelliteCropListTo;
  zones?: ZoneResponse[];
}

export interface SatelliteCropListTo {
  name?: string;
  internalId?: string;
  legislativeCode: number;
}

export interface VariableApplicationPutRequestBody {
  id?: string;
  actionId?: string;
  createdDate?: string;
  cropLegCode: number;
  parcelId: string;
  farmId: string;
  doseHa: number;
  areaHa: number;
  materialId: string;
  mmu?: number;
  unit: string;
  type: VariableApplicationType;
  accountableN?: number;
  p?: number;
  k?: number;
  index: IndexResponse;
  zoneType?: VariableApplicationZoneType;
  applicationZones: VariableApplicationZoneExtendedResponse[];
  totalDose: number;
}

export type VariableApplicationType = typeof VariableApplicationType[keyof typeof VariableApplicationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VariableApplicationType = {
  STRONG_ZONES_SEEDING: 'STRONG_ZONES_SEEDING',
  WEAK_ZONES_SEEDING: 'WEAK_ZONES_SEEDING',
  WEAK_ZONES_FERTILIZATION: 'WEAK_ZONES_FERTILIZATION',
  STRONG_ZONES_FERTILIZATION: 'STRONG_ZONES_FERTILIZATION',
  RECOMPUTE: 'RECOMPUTE',
} as const;

export interface VariableApplicationZoneExtendedResponse {
  variableApplicationId?: string;
  zoneId: number;
  color: string;
  quality: number;
  geometry: GeoJsonMultiPolygon;
  areaHa: number;
  doseHa: number;
  totalDose: number;
  accountableN?: number;
  p?: number;
  k?: number;
  id?: string;
}

export type VariableApplicationZoneType = typeof VariableApplicationZoneType[keyof typeof VariableApplicationZoneType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VariableApplicationZoneType = {
  PLANET_BIOMONITORING_SAVI: 'PLANET_BIOMONITORING_SAVI',
  SENTINEL_HUB_MANAGEMENT_ZONES_SAVI: 'SENTINEL_HUB_MANAGEMENT_ZONES_SAVI',
} as const;

export type VegetationIndex = typeof VegetationIndex[keyof typeof VegetationIndex];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VegetationIndex = {
  CAB: 'CAB',
  CW: 'CW',
  LAI: 'LAI',
  SAVI: 'SAVI',
  NDWI: 'NDWI',
  BSI: 'BSI',
  SAMPLES: 'SAMPLES',
} as const;

export interface ZoneResponse {
  zoneValue?: number;
  zoneNumber?: number;
  geometry?: GeoJsonMultiPolygon;
  areaHa?: number;
  mmuHa?: number;
  color?: string;
}

export interface VariableApplicationResponse {
  id?: string;
  actionId?: string;
  createdDate?: string;
  cropLegCode: number;
  parcelId: string;
  farmId: string;
  doseHa: number;
  areaHa: number;
  materialId: string;
  mmu?: number;
  unit: string;
  type: VariableApplicationType;
  accountableN?: number;
  p?: number;
  k?: number;
  index: IndexResponse;
  zoneType?: VariableApplicationZoneType;
  applicationZones: VariableApplicationZoneExtendedResponse[];
  totalDose: number;
  averageQuality: number;
}

export interface SeasonTo {
  id: string;
  name: string;
  dateFrom: string;
}

export interface SeasonParcelMetadataTo {
  seasonId: string;
  parcelId: string;
  cropInternalId?: string;
  yieldInTonnes?: number;
}

export interface DashboardParcelTo {
  parcelId: string;
  externalParcelId: string;
  localName: string;
  blockNr: string;
  areaInHa?: number;
  cropName?: string;
  cropInternalId?: string;
  yieldInTonnes?: number;
  products?: PrecisionFarmingProduct[];
}

export interface DashboardSeasonTo {
  seasonId: string;
  dateFrom: string;
  name: string;
  parcels: DashboardParcelTo[];
  totalAreaHa: number;
}

export type PrecisionFarmingProduct = typeof PrecisionFarmingProduct[keyof typeof PrecisionFarmingProduct];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrecisionFarmingProduct = {
  PLANET_BIOMONITORING_SAVI: 'PLANET_BIOMONITORING_SAVI',
  SENTINEL_HUB_MANAGEMENT_ZONES_SAVI: 'SENTINEL_HUB_MANAGEMENT_ZONES_SAVI',
  GISAT_BIOPHYSICAL_MONITORING: 'GISAT_BIOPHYSICAL_MONITORING',
  INTERNAL_SOIL_SAMPLES: 'INTERNAL_SOIL_SAMPLES',
  VARIABLE_APPLICATION: 'VARIABLE_APPLICATION',
  INTERNAL_YIELD_MAPS: 'INTERNAL_YIELD_MAPS',
} as const;

export type IntegrationType = typeof IntegrationType[keyof typeof IntegrationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IntegrationType = {
  TASKDATA: 'TASKDATA',
  AGINTEGRATED: 'AGINTEGRATED',
  VARIODOC: 'VARIODOC',
} as const;

export type ParcelsConfigUpdateRequestProductDates = {[key: string]: ProductDates};

export interface ParcelsConfigUpdateRequest {
  parcelId: string[];
  managementZonesEnabled?: boolean;
  internalBiomonitoringEnabled?: boolean;
  biomonitoringEnabled?: boolean;
  variableApplicationEnabled?: boolean;
  soilSamplesEnabled?: boolean;
  yieldMapsEnabled?: boolean;
  productDates?: ParcelsConfigUpdateRequestProductDates;
}

export interface ProductDates {
  enabledDate?: string;
  stoppedDate?: string;
}

export interface ParcelConfigUpdateRequest {
  historyPotentialEnabled?: boolean;
  biomonitoringEnabled?: boolean;
  yieldMapsEnabled?: boolean;
  soilSamplesEnabled?: boolean;
}

export interface UpdateYieldMapClassificationRequest {
  cropId: string;
  internalLabel?: string;
  values?: number[];
  classes?: number[];
}

export interface UpdateYieldMapClassificationResponse {
  intervalsId: number;
  previousValues: number[];
  previousClasses: number[];
  updatedValues: number[];
  updatedClasses: number[];
}

export interface IntegrationAdminPutTo {
  type: IntegrationType;
  enabled: boolean;
}

export type CatalogueType = typeof CatalogueType[keyof typeof CatalogueType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CatalogueType = {
  EAGRI: 'EAGRI',
  GLOBAL: 'GLOBAL',
  PPA_SK: 'PPA_SK',
} as const;

export interface UpdateRecordsStatusTo {
  status: UpdateStatus;
  updatedRecords: number;
}

export type UpdateStatus = typeof UpdateStatus[keyof typeof UpdateStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateStatus = {
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
} as const;

export type HttpStatus = typeof HttpStatus[keyof typeof HttpStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HttpStatus = {
  '100_CONTINUE': '100 CONTINUE',
  '101_SWITCHING_PROTOCOLS': '101 SWITCHING_PROTOCOLS',
  '102_PROCESSING': '102 PROCESSING',
  '103_EARLY_HINTS': '103 EARLY_HINTS',
  '103_CHECKPOINT': '103 CHECKPOINT',
  '200_OK': '200 OK',
  '201_CREATED': '201 CREATED',
  '202_ACCEPTED': '202 ACCEPTED',
  '203_NON_AUTHORITATIVE_INFORMATION': '203 NON_AUTHORITATIVE_INFORMATION',
  '204_NO_CONTENT': '204 NO_CONTENT',
  '205_RESET_CONTENT': '205 RESET_CONTENT',
  '206_PARTIAL_CONTENT': '206 PARTIAL_CONTENT',
  '207_MULTI_STATUS': '207 MULTI_STATUS',
  '208_ALREADY_REPORTED': '208 ALREADY_REPORTED',
  '226_IM_USED': '226 IM_USED',
  '300_MULTIPLE_CHOICES': '300 MULTIPLE_CHOICES',
  '301_MOVED_PERMANENTLY': '301 MOVED_PERMANENTLY',
  '302_FOUND': '302 FOUND',
  '302_MOVED_TEMPORARILY': '302 MOVED_TEMPORARILY',
  '303_SEE_OTHER': '303 SEE_OTHER',
  '304_NOT_MODIFIED': '304 NOT_MODIFIED',
  '305_USE_PROXY': '305 USE_PROXY',
  '307_TEMPORARY_REDIRECT': '307 TEMPORARY_REDIRECT',
  '308_PERMANENT_REDIRECT': '308 PERMANENT_REDIRECT',
  '400_BAD_REQUEST': '400 BAD_REQUEST',
  '401_UNAUTHORIZED': '401 UNAUTHORIZED',
  '402_PAYMENT_REQUIRED': '402 PAYMENT_REQUIRED',
  '403_FORBIDDEN': '403 FORBIDDEN',
  '404_NOT_FOUND': '404 NOT_FOUND',
  '405_METHOD_NOT_ALLOWED': '405 METHOD_NOT_ALLOWED',
  '406_NOT_ACCEPTABLE': '406 NOT_ACCEPTABLE',
  '407_PROXY_AUTHENTICATION_REQUIRED': '407 PROXY_AUTHENTICATION_REQUIRED',
  '408_REQUEST_TIMEOUT': '408 REQUEST_TIMEOUT',
  '409_CONFLICT': '409 CONFLICT',
  '410_GONE': '410 GONE',
  '411_LENGTH_REQUIRED': '411 LENGTH_REQUIRED',
  '412_PRECONDITION_FAILED': '412 PRECONDITION_FAILED',
  '413_PAYLOAD_TOO_LARGE': '413 PAYLOAD_TOO_LARGE',
  '413_REQUEST_ENTITY_TOO_LARGE': '413 REQUEST_ENTITY_TOO_LARGE',
  '414_URI_TOO_LONG': '414 URI_TOO_LONG',
  '414_REQUEST_URI_TOO_LONG': '414 REQUEST_URI_TOO_LONG',
  '415_UNSUPPORTED_MEDIA_TYPE': '415 UNSUPPORTED_MEDIA_TYPE',
  '416_REQUESTED_RANGE_NOT_SATISFIABLE': '416 REQUESTED_RANGE_NOT_SATISFIABLE',
  '417_EXPECTATION_FAILED': '417 EXPECTATION_FAILED',
  '418_I_AM_A_TEAPOT': '418 I_AM_A_TEAPOT',
  '419_INSUFFICIENT_SPACE_ON_RESOURCE': '419 INSUFFICIENT_SPACE_ON_RESOURCE',
  '420_METHOD_FAILURE': '420 METHOD_FAILURE',
  '421_DESTINATION_LOCKED': '421 DESTINATION_LOCKED',
  '422_UNPROCESSABLE_ENTITY': '422 UNPROCESSABLE_ENTITY',
  '423_LOCKED': '423 LOCKED',
  '424_FAILED_DEPENDENCY': '424 FAILED_DEPENDENCY',
  '425_TOO_EARLY': '425 TOO_EARLY',
  '426_UPGRADE_REQUIRED': '426 UPGRADE_REQUIRED',
  '428_PRECONDITION_REQUIRED': '428 PRECONDITION_REQUIRED',
  '429_TOO_MANY_REQUESTS': '429 TOO_MANY_REQUESTS',
  '431_REQUEST_HEADER_FIELDS_TOO_LARGE': '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
  '451_UNAVAILABLE_FOR_LEGAL_REASONS': '451 UNAVAILABLE_FOR_LEGAL_REASONS',
  '500_INTERNAL_SERVER_ERROR': '500 INTERNAL_SERVER_ERROR',
  '501_NOT_IMPLEMENTED': '501 NOT_IMPLEMENTED',
  '502_BAD_GATEWAY': '502 BAD_GATEWAY',
  '503_SERVICE_UNAVAILABLE': '503 SERVICE_UNAVAILABLE',
  '504_GATEWAY_TIMEOUT': '504 GATEWAY_TIMEOUT',
  '505_HTTP_VERSION_NOT_SUPPORTED': '505 HTTP_VERSION_NOT_SUPPORTED',
  '506_VARIANT_ALSO_NEGOTIATES': '506 VARIANT_ALSO_NEGOTIATES',
  '507_INSUFFICIENT_STORAGE': '507 INSUFFICIENT_STORAGE',
  '508_LOOP_DETECTED': '508 LOOP_DETECTED',
  '509_BANDWIDTH_LIMIT_EXCEEDED': '509 BANDWIDTH_LIMIT_EXCEEDED',
  '510_NOT_EXTENDED': '510 NOT_EXTENDED',
  '511_NETWORK_AUTHENTICATION_REQUIRED': '511 NETWORK_AUTHENTICATION_REQUIRED',
} as const;

export type MeasurementKind = typeof MeasurementKind[keyof typeof MeasurementKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MeasurementKind = {
  FULL: 'FULL',
  BACK_CAMERA: 'BACK_CAMERA',
} as const;

export interface MeasurementRequestTo {
  measurementGroupId: string;
  measurementName: string;
  measurementKind: MeasurementKind;
  latitude: number;
  longitude: number;
  /** Note for measurement. Only for BACK_CAMERA measurement kind. */
  note?: string;
  filename: string;
}

export interface PresignedUrlResponseTo {
  highResImageUrl: string;
  measurementId: string;
  spectrometerUrl?: string;
  calibrationImageUrl?: string;
  colorImage1Url?: string;
  colorImage2Url?: string;
  colorImage3Url?: string;
  colorImage4Url?: string;
  colorImage5Url?: string;
  colorImage6Url?: string;
  colorImage7Url?: string;
  colorImage8Url?: string;
  colorImage9Url?: string;
  colorImage10Url?: string;
  colorImage11Url?: string;
  colorImage12Url?: string;
}

export interface Unit { [key: string]: unknown }

export interface MeasurementGroupRequestTo {
  name: string;
}

export interface MeasurementGroupResponseTo {
  id: string;
  name: string;
  lastMeasurementCreated?: string;
  countMeasurements: number;
}

export interface FarmUpdateResultTo {
  permanentlyRetired: RetiredParcelTo[];
  historizations: ParcelHistorizationSatelliteTo[];
}

export interface GeoJsonPolygon {
  type: GeoJsonType;
  coordinates: number[][][];
  geometries: GeoJson[];
}

export interface NewParcelTo {
  parcelId: string;
  geometry: GeoJsonPolygon;
}

export interface ParcelHistorizationSatelliteTo {
  retiredParcels: RetiredParcelTo[];
  newParcels: NewParcelTo[];
}

export interface RetiredParcelTo {
  parcelId: string;
}

export interface GeoJsonPoint {
  type: GeoJsonType;
  coordinates: number[];
}

export interface SoilSamplesRequestBody {
  farmId: string;
  parcelId: string;
  samples: SoilSamplesSample[];
  zones: SoilSamplesZone[];
}

export interface SoilSamplesSample {
  sample: string;
  value: number;
  geometry: GeoJsonPoint;
}

export interface SoilSamplesZone {
  sample: string;
  zoneStrength: number;
  geometry: GeoJson;
}

export interface SampleUploadResult {
  element: string;
  value: number;
}

export interface SampleZoneUploadResult {
  element: string;
  zoneStrength: number;
}

export interface SamplesUploadResult {
  parcelId: string;
  soilSamplesInternalId: number;
  samples: SampleUploadResult[];
  zones: SampleZoneUploadResult[];
}

export type MeasurementElement = typeof MeasurementElement[keyof typeof MeasurementElement];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MeasurementElement = {
  CLAY: 'CLAY',
  PH: 'PH',
  COX: 'COX',
  ORGANIC: 'ORGANIC',
  N: 'N',
  K: 'K',
  CA: 'CA',
  MG: 'MG',
  S: 'S',
  FE: 'FE',
  P: 'P',
  SUS: 'SUS',
  DUST: 'DUST',
  SAND: 'SAND',
} as const;

export interface MeasurementPredictionRequest {
  measurementId: string;
  source: MeasurementSource;
  predictions: MeasurementPredictionRequestPrediction[];
}

export interface MeasurementPredictionRequestPrediction {
  element: MeasurementElement;
  value: number;
}

export type MeasurementSource = typeof MeasurementSource[keyof typeof MeasurementSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MeasurementSource = {
  SPECTROMETER: 'SPECTROMETER',
  CAMERA: 'CAMERA',
} as const;

export interface ReloadResultTo {
  ok: string[];
  failed: string[];
}

export interface VariodocCreateTo {
  username: string;
  password: string;
}

export interface MachineUploadRequestTo {
  id: number;
  name: string;
}

export interface MachinesUploadRequestTo {
  machines: MachineUploadRequestTo[];
}

export interface IntegrationEnableTo {
  email: string;
  name: string;
}

export type TaskDataImportError = typeof TaskDataImportError[keyof typeof TaskDataImportError];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskDataImportError = {
  INVALID_ISO_XML: 'INVALID_ISO_XML',
  CONFLICT: 'CONFLICT',
} as const;

export interface TaskDataImportResponseFileTo {
  file: string;
  success: boolean;
  error?: TaskDataImportError;
}

export interface TaskDataImportResponseTo {
  file: TaskDataImportResponseFileTo[];
}

export interface TaskDataDownloadTo {
  tasks: number[];
}

export interface CreateSeasonTo {
  name: string;
  dateFrom: string;
}

export type DoseUnit = typeof DoseUnit[keyof typeof DoseUnit];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DoseUnit = {
  KGHA: 'KGHA',
} as const;

export type MaterialDistributionStrategy = typeof MaterialDistributionStrategy[keyof typeof MaterialDistributionStrategy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MaterialDistributionStrategy = {
  WEAK_ZONES: 'WEAK_ZONES',
  STRONG_ZONES: 'STRONG_ZONES',
} as const;

export interface PrescriptionDraftCreateRequest {
  materialId: string;
  materialType: PrescriptionMaterialType;
  minDose: number;
  maxDose: number;
  doseUnit: DoseUnit;
  product: PrescriptionMapProduct;
  productSpecialization?: string;
  parcels: string[];
  strategy: MaterialDistributionStrategy;
  seasonId: string;
}

export type PrescriptionMapProduct = typeof PrescriptionMapProduct[keyof typeof PrescriptionMapProduct];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrescriptionMapProduct = {
  PLANET_BIOMONITORING: 'PLANET_BIOMONITORING',
  SENTINEL_HUB_MANAGEMENT_ZONES: 'SENTINEL_HUB_MANAGEMENT_ZONES',
  BIOPHYSICAL_MONITORING: 'BIOPHYSICAL_MONITORING',
  INTERNAL_SOIL_SAMPLES: 'INTERNAL_SOIL_SAMPLES',
  INTERNAL_YIELD_MAPS: 'INTERNAL_YIELD_MAPS',
} as const;

export type PrescriptionMaterialType = typeof PrescriptionMaterialType[keyof typeof PrescriptionMaterialType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrescriptionMaterialType = {
  FERTILIZER: 'FERTILIZER',
  PLANT_PROTECTION: 'PLANT_PROTECTION',
  SEED: 'SEED',
} as const;

export interface PrescriptionDraftCreateResponse {
  draftId: string;
  parcelsWithPrescriptionMaps: string[];
  parcelsWithoutPrescriptionMaps: string[];
}

export interface AddAgroevidenceParcelsTo {
  seasonId: string;
  parcelIds: string[];
}

export type BiomonitoringResponseStatus = typeof BiomonitoringResponseStatus[keyof typeof BiomonitoringResponseStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BiomonitoringResponseStatus = {
  OK: 'OK',
  NOT_FOUND: 'NOT_FOUND',
  ERROR: 'ERROR',
} as const;

export interface ParcelConfigInsertRequest {
  parcelId: string;
  biomonitoringEnabled: boolean;
  variableApplicationEnabled: boolean;
  yieldMapsEnabled?: boolean;
  soilSamplesEnabled?: boolean;
}

export type FarmPfVersionEnum = typeof FarmPfVersionEnum[keyof typeof FarmPfVersionEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FarmPfVersionEnum = {
  '1_0_0': '1_0_0',
  '2_0_0': '2_0_0',
} as const;

export interface FarmPfVersionTo {
  farmId: string;
  version: FarmPfVersionEnum;
}

export interface AdminYieldMapReloadClassificationsResult {
  insertedItems: InsertedYieldMapClassification[];
}

export interface ClassificationCrop {
  id?: number;
  classificationIntervalsId?: number;
  cropId: string;
  created?: string;
  updated?: string;
}

export interface ClassificationIntervals {
  id?: number;
  values: number[];
  classes: number[];
  created?: string;
  updated?: string;
  internalLabel?: string;
}

export interface InsertedYieldMapClassification {
  intervals: ClassificationIntervals;
  crops: ClassificationCrop[];
}

export type GeometryUpdateType = typeof GeometryUpdateType[keyof typeof GeometryUpdateType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GeometryUpdateType = {
  UPDATE_BY_FARM_ID: 'UPDATE_BY_FARM_ID',
  UPDATE_ALL_EMPTY_GEOMETRIES: 'UPDATE_ALL_EMPTY_GEOMETRIES',
} as const;

export type MethodologyVersion = typeof MethodologyVersion[keyof typeof MethodologyVersion];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MethodologyVersion = {
  VERSION_1_0_0: 'VERSION_1_0_0',
  VERSION_2_0_0: 'VERSION_2_0_0',
} as const;

export interface ManagementZoneAuditDto {
  parcelId: string;
  farmId: string;
  enabled: string;
  disabled?: string;
}

export interface InternalBiomonitoringAuditTo {
  parcelId: string;
  farmId: string;
  enabled: string;
  disabled?: string;
}

export interface TaskDataPatchTaskDataTo {
  name?: string;
  archived?: boolean;
}

export type TaskDataPatchToTasks = {[key: string]: TaskDataPatchTaskDataTo};

export interface TaskDataPatchTo {
  tasks: TaskDataPatchToTasks;
}

export interface MeasurementResponseTo {
  id: string;
  measurementGroupId: string;
  measurementName: string;
  measurementKind: MeasurementKind;
  created: string;
  latitude: number;
  longitude: number;
  note?: string;
  photoUrl: string;
  predictions: PredictionTo[];
}

export interface PredictionTo {
  element: MeasurementElement;
  source: MeasurementSource;
  value: number;
}

export interface InternalColorPaletteResponse {
  methodologyVersion?: string;
  colors: InternalColorPalleteTo[];
}

export interface InternalColorPalleteTo {
  hexCode: string;
  index: string;
}

export interface ExtentCoordinates {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

export interface YieldMapDetailResponse {
  avgYieldTonnes: number;
  cropId: string;
  cropName: string;
  zones: YieldMapDetailZone[];
  geotiffUrl: string;
  rasterUrl: string;
  rasterExtent: ExtentCoordinates;
  legend: YieldMapLegendItem[];
  areaHa: number;
}

export interface YieldMapDetailZone {
  zoneIndex: string;
  geometry: GeoJsonMultiPolygon;
  areaHa: number;
  color: string;
}

export interface YieldMapLegendItem {
  order: number;
  zoneIndex: number;
  from: number;
  to: number;
  color: string;
}

export type ExportType = typeof ExportType[keyof typeof ExportType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExportType = {
  COMMON: 'COMMON',
  TRIMBLE: 'TRIMBLE',
} as const;

export interface AgIntegratedJobsTo {
  jobId: number;
  parentId?: number;
  type: JobType;
  name?: string;
  mapType?: string;
  exportDate: string;
  status: string;
}

export type JobType = typeof JobType[keyof typeof JobType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobType = {
  WRITER: 'WRITER',
  UPLOADER: 'UPLOADER',
} as const;

export interface VariableActionZonesTo {
  type: VariableApplicationZoneType;
  dateFrom?: string;
  dateTo?: string;
  zones: ZoneDto[];
}

export interface ZoneDto {
  index: string;
  areaHa: number;
  quality?: number;
  color: string;
  geometry: GeoJsonMultiPolygon;
}

export interface IntegrationSettingsTo {
  url: string;
}

export interface MachineTo {
  id: number;
  name: string;
}

export interface TaskDataListTaskTo {
  id: string;
  name?: string;
  duration: number;
  dateFrom?: string;
  dateTo?: string;
}

export interface TaskDataListTo {
  id: number;
  name: string;
  filename: string;
  dateFrom?: string;
  dateTo?: string;
  duration: number;
  tasks: TaskDataListTaskTo[];
  source: TaskSource;
  archived: boolean;
  created: string;
}

export type TaskSource = typeof TaskSource[keyof typeof TaskSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskSource = {
  MANUAL: 'MANUAL',
  VARIODOC: 'VARIODOC',
} as const;

export interface TaskDataAttributeTo {
  attribute: string;
  name: string;
  codes: string[];
}

export interface TaskDataCulturalPracticeTo {
  id: string;
  name?: string;
}

export interface TaskDataDeviceTo {
  id: string;
  name?: string;
  clientName: string;
  serialNumber?: string;
}

export interface TaskDataPartfieldTo {
  id: string;
  name: string;
  /** Size of the partfield in m2 */
  area: number;
}

export interface TaskDataProductTo {
  id: string;
  name?: string;
}

export interface TaskDataTaskTo {
  id: string;
  task: TaskDataListTo;
  name?: string;
  duration: number;
  dateFrom?: string;
  dateTo?: string;
  attributes: TaskDataAttributeTo[];
  product: TaskDataProductTo[];
  partfield?: TaskDataPartfieldTo;
  culturalPractice?: TaskDataCulturalPracticeTo;
  device: TaskDataDeviceTo[];
  worker?: TaskDataWorkerTo;
  source: TaskSource;
  created: string;
}

export interface TaskDataWorkerTo {
  id: string;
  name?: string;
}

export type TaskAttribute = typeof TaskAttribute[keyof typeof TaskAttribute];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskAttribute = {
  YIELD: 'YIELD',
  MOISTURE: 'MOISTURE',
  APPLIED_DOSE: 'APPLIED_DOSE',
  PRESCRIPTION_DOSE: 'PRESCRIPTION_DOSE',
  ACTUAL_SPEED: 'ACTUAL_SPEED',
  FUEL_CONSUMPTION_TIME: 'FUEL_CONSUMPTION_TIME',
  ENGINE_RPM: 'ENGINE_RPM',
  TOTAL_FUEL_CONSUMPTION: 'TOTAL_FUEL_CONSUMPTION',
  TOTAL_VOLUME: 'TOTAL_VOLUME',
  TOTAL_DISTANCE: 'TOTAL_DISTANCE',
  TOTAL_AREA: 'TOTAL_AREA',
} as const;

export interface TaskDataTimelineAttributeTo {
  value: number;
  unit?: string;
}

export interface TaskDataTimelineTo {
  time: string;
  latitude: number;
  longitude: number;
  attribute?: TaskDataTimelineAttributeTo;
}

export interface TaskDataReportAttributeTo {
  value?: number;
  unit?: string;
}

export type TaskDataReportToReport = {[key: string]: TaskDataReportAttributeTo};

export interface TaskDataReportTo {
  report: TaskDataReportToReport;
}

export interface SoilSamplesResponse {
  farmId: string;
  parcelId: string;
  samples: SoilSamplesSample[];
  zones: SoilSamplesZone[];
}

export interface SeasonWithCropTo {
  season: SeasonTo;
  cropInternalId?: string;
}

export interface ExternalParcelTo {
  parcelId: string;
  localName: string;
  blockNr: string;
  area?: number;
  cropName?: string;
  isSeasonMember: boolean;
}

export interface AvailableParcelProduct {
  product: PrecisionFarmingProduct;
  specializations?: string[];
}

export type ParcelProductStatus = typeof ParcelProductStatus[keyof typeof ParcelProductStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParcelProductStatus = {
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  AWAITING_USER: 'AWAITING_USER',
  AWAITING_DATA: 'AWAITING_DATA',
  HISTORICAL: 'HISTORICAL',
} as const;

export interface ParcelProductTo {
  biomonitoringStatus: ParcelProductStatus;
  internalBiomonitoringStatus: ParcelProductStatus;
  variableApplicationStatus: ParcelProductStatus;
  managementZonesStatus: ParcelProductStatus;
}

export type MaterialType = typeof MaterialType[keyof typeof MaterialType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MaterialType = {
  FERTILIZER: 'FERTILIZER',
  PLANT_PROTECTION: 'PLANT_PROTECTION',
  SEED: 'SEED',
} as const;

export type DoseTotalUnit = typeof DoseTotalUnit[keyof typeof DoseTotalUnit];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DoseTotalUnit = {
  KG: 'KG',
} as const;

export interface PrescriptionMapListResponse {
  id: string;
  doseUnit: DoseUnit;
  doseTotalUnit: DoseTotalUnit;
  totalParcels: number;
  totalAreaHa: number;
  minDose: number;
  maxDose: number;
  avgDose: number;
  totalDose: number;
  created?: string;
  materialName: string;
}

export interface PrescriptionMapPageResponse {
  total: number;
  data: PrescriptionMapListResponse[];
}

export interface PrescriptionMapParcelResponse {
  id: string;
  parcelName: string;
  parcelShortName: string;
  prescriptionProduct: PrescriptionMapProduct;
  totalAreaHa: number;
  minDose: number;
  maxDose: number;
  avgDose: number;
  totalDose: number;
  cropName?: string;
}

export interface ParcelProductDto {
  parcelId: string;
  productType: PrecisionFarmingProduct;
  createdDate?: string;
  enabledDate?: string;
  receivedDate?: string;
  callbackUrl?: string;
  stoppedDate?: string;
  callbackExpiration?: string;
}

export interface SatelliteParcel {
  id: string;
  localName: string;
  blockNr: string;
  area: number;
  status: string;
  biomonitoring: boolean;
  internalBiomonitoring: boolean;
  variableApplication: boolean;
  historyPotential: boolean;
  managementZones: boolean;
  soilSamples: boolean;
  activeBiomonitoringIndices: VegetationIndex[];
  products: ParcelProductDto[];
}

export interface InternalDashboardParcel {
  parcelId: string;
  externalParcelId: string;
  localName: string;
  blockNr: string;
  areaInHa: number;
  cropName?: string;
  created?: string;
  cropInternalId?: string;
  yieldInTonnes?: number;
  products: PrecisionFarmingProduct[];
}

export interface SatelliteParcelV2 {
  id: string;
  externalParcelId: string;
  localName: string;
  blockNr: string;
  areaHa: number;
  area: number;
  biomonitoring: boolean;
  internalBiomonitoring: boolean;
  managementZones: boolean;
  soilSamples: boolean;
  yieldMaps: boolean;
  cropInternalId?: string;
  yieldInTonnes?: number;
  activeBiomonitoringIndices: VegetationIndex[];
  products: ParcelProductDto[];
  geometry: GeoJsonMultiPolygon;
}

export interface AeParcelV2To {
  id: string;
  externalParcelId: string;
  blockNumber: string;
  localName: string;
  area: number;
  lpisBlockArea: number;
  nitrateVulnerable: boolean;
  validFrom: string;
  validTo?: string;
  status: ParcelStatus;
  zones?: ZoneTo[];
  center?: CenterTo;
  landUseName: string;
  seedApplication?: SeedApplicationTo;
  source: ParcelSource;
  zone?: ZoneTo;
}

export type CatalogueRole = typeof CatalogueRole[keyof typeof CatalogueRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CatalogueRole = {
  OWNER: 'OWNER',
  EDITOR: 'EDITOR',
  VIEWER: 'VIEWER',
} as const;

export interface CatalogueTo {
  id: string;
  type: CatalogueType;
  role: CatalogueRole;
  canUpdate: boolean;
}

export interface CenterTo {
  id: string;
  key: string;
  name: string;
}

export interface ColorTo {
  red: number;
  green: number;
  blue: number;
  asDecimal: number;
}

export interface CropGroupTo {
  /** Crop group legislative id / external id */
  id: string;
  externalId: string;
  name?: string;
  legislativeCode: string;
}

export interface CropSeedTo {
  id: string;
  catalogue: CatalogueTo;
  isHidden: boolean;
  isFavorite: boolean;
  varietyName?: string;
  crop: CropTo;
  name: string;
  legislativeCode: number;
}

export interface CropTo {
  id: string;
  name: string;
  externalId: number;
  catalogueType: CatalogueType;
  group?: CropGroupTo;
  validFrom?: string;
  validTo?: string;
  color: ColorTo;
  textColor: ColorTo;
  pfCode?: string;
  legislativeCode: number;
}

export type EagriCropUseType = typeof EagriCropUseType[keyof typeof EagriCropUseType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EagriCropUseType = {
  NUMBER_1: '1',
  NUMBER_2: '2',
  NUMBER_3: '3',
  NUMBER_4: '4',
  NUMBER_6: '6',
  NUMBER_7: '7',
} as const;

export type ParcelSource = typeof ParcelSource[keyof typeof ParcelSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParcelSource = {
  MANUAL: 'MANUAL',
  SHP: 'SHP',
  AGROEVIDENCE_EAGRI: 'AGROEVIDENCE_EAGRI',
  AGROEVIDENCE_EAGRI_AUTOMATIC: 'AGROEVIDENCE_EAGRI_AUTOMATIC',
  CORE: 'CORE',
} as const;

export type ParcelStatus = typeof ParcelStatus[keyof typeof ParcelStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParcelStatus = {
  OPEN: 'OPEN',
  RETIRED: 'RETIRED',
} as const;

export interface SeedApplicationTo {
  id: string;
  seedingActionId: string;
  seed: CropSeedTo;
  type: SeedApplicationType;
  startDate: string;
  endDate?: string;
  cropUseType?: EagriCropUseType;
  terminalActionId?: string;
}

export type SeedApplicationType = typeof SeedApplicationType[keyof typeof SeedApplicationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SeedApplicationType = {
  CATCH_CROP: 'CATCH_CROP',
  MAIN_CROP: 'MAIN_CROP',
} as const;

export interface ZoneTo {
  id: string;
  name: string;
}

export interface SeasonsDashboardTo {
  seasons: DashboardSeasonTo[];
  parcels: DashboardParcelTo[];
}

export interface InternalCropTo {
  id: string;
  externalId: string;
  name: string;
  pfCode?: string;
}

export interface ManagementZoneResponseDto {
  parcelId: string;
  farmId: string;
  validity: string;
  geotiffUrl: string;
  rawGeotiffUrl?: string;
  rasterUrl: string;
  rasterCoordinates: ExtentCoordinates;
  savi: ZoneDto[];
  averageQuality: number;
  totalAreaHa: number;
}

export interface BiomonitoringIntervalResponse {
  dateFrom: string;
  dateTo: string;
  crop: SatelliteCropListTo;
  snapshots: BiomonitoringSnapResponse[];
  status?: string;
}

export interface BiomonitoringSnapResponse {
  value?: number;
  indexType: string;
  methodologyType?: string;
  imgPath?: string;
  fileUrl: string;
  zonedTiffUrl?: string;
  zonedMmuHa?: number;
  zones: ZoneResponse[];
  imgExtent?: EnvelopeResponse;
}

export interface EnvelopeResponse {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

export type BiomonitoringIndexType = typeof BiomonitoringIndexType[keyof typeof BiomonitoringIndexType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BiomonitoringIndexType = {
  SAVI: 'SAVI',
  NDWI: 'NDWI',
  RGB: 'RGB',
} as const;

export interface CropIntervalTo {
  crop?: SatelliteCropListTo;
  to?: string;
  from: string;
}

export interface BiomonitoringIndexRecordDto {
  id: string;
  type: BiomonitoringIndexType;
  dateFrom: string;
  dateTo: string;
  average?: number;
  rasterUrl: string;
  geotiffUrl: string;
  rasterCoordinates?: GeoJsonPolygon;
  imgExtent?: EnvelopeResponse;
  zones: BiomonitoringZoneDto[];
}

export interface BiomonitoringIndexRecordsDto {
  validity: string;
  records: BiomonitoringIndexRecordDto[];
}

export interface BiomonitoringZoneDto {
  index: string;
  geometry: GeoJsonMultiPolygon;
  areaHa: number;
  color: string;
}

export interface ParcelDataTo {
  parcelId: string;
  farmId: string;
  originalArea: number;
  historyPotentialEnabled: boolean;
  biomonitoringEnabled: boolean;
  variableApplicationEnabled: boolean;
  name?: string;
  blockNr?: string;
  area?: number;
  cropName?: string;
  internalBiomonitoringEnabled: boolean;
  managementZonesEnabled: boolean;
  yieldMapsEnabled: boolean;
  products?: ParcelProductDto[];
}

export interface FarmStatistics {
  monitoringGisat: number;
  monitoringGisatArea: number;
  monitoringPlanet: number;
  monitoringPlanetArea: number;
  managementZones: number;
  managementZonesArea: number;
  yieldMaps: number;
  yieldMapsArea: number;
  pfParcelsCount: number;
  pfParcelsArea: number;
  allParcelsCount: number;
  allParcelsArea: number;
}

export interface ManagementZoneAudit {
  id?: string;
  parcelId?: string;
  farmId: string;
  created?: string;
  enabled: string;
  disabled?: string;
  lastSaviIndex?: string;
  lastBarrenSoilIndex?: string;
  satelliteParcelId?: string;
}

export interface IntegrationAdminTo {
  enabled: boolean;
}

export interface InternalBiomonitoringAudit {
  id?: string;
  parcelId?: string;
  farmId: string;
  created?: string;
  enabled: string;
  disabled?: string;
  satelliteParcelId?: string;
}

export interface TaskDataDeleteTo {
  task: number[];
}

export type GetAgIntegratedIntegrationParams = {
farmIds: string;
};

export type EnableAgIntegratedIntegrationParams = {
farmIds: string;
};

export type CreateAgIntegratedIntegrationParams = {
farmIds: string;
};

export type DisableAgIntegratedIntegrationParams = {
farmIds: string;
};

export type GetVariableApplicationByActionIdParams = {
farmIds: string;
};

export type SaveVariableApplicationParams = {
farmIds: string;
};

export type DeleteVariableApplicationsForActionParams = {
farmIds: string;
};

export type DeleteVariableApplicationsForAction200 = { [key: string]: unknown };

export type GetSeasonsParams = {
farmIds: string;
page?: number;
offset?: number;
'per-page'?: number;
'sort-dir'?: string;
};

export type UpdateSeasonParams = {
farmIds: string;
};

export type UpdateSeason200 = { [key: string]: unknown };

export type UpdateSeason404 = { [key: string]: unknown };

export type UpdateSeason409 = { [key: string]: unknown };

export type CreateSeasonParams = {
farmIds: string;
'source-season-id'?: string;
};

export type UpdateSeasonParcelMetadataParams = {
farmIds: string;
};

export type GetIntegrationParams = {
farmIds: string;
type: IntegrationType;
};

export type EnableIntegrationParams = {
farmIds: string;
type: IntegrationType;
};

export type DisableIntegrationParams = {
farmIds: string;
type: IntegrationType;
};

export type GetParcelDataParams = {
localNameBlockNr?: string;
farmIds: string;
'monitoring-gisat'?: boolean;
'monitoring-planet'?: boolean;
'management-zones'?: boolean;
'growth-potential'?: boolean;
'yield-maps'?: boolean;
crops?: string[];
pageOffset?: number;
pageSize?: number;
sortProperty?: string;
language?: string;
};

export type UpdateParcelsProductsParams = {
farmIds: string;
};

export type UpdateParcelsProducts200 = { [key: string]: unknown };

export type SaveParcelsDataParams = {
farmIds: string;
};

export type UpdateParcelProductsParams = {
farmIds: string;
};

export type UpdateParcelProducts200 = { [key: string]: unknown };

export type _DeleteParams = {
farmIds: string[];
};

export type UpdateV2ParcelsProductsParams = {
farmIds: string;
};

export type UpdateV2ParcelsProducts200 = { [key: string]: unknown };

export type ReloadYieldMapClassificationBody = {
  file: Blob;
};

export type GetIntegration1Params = {
farmIds: string;
type: IntegrationType;
};

export type PutIntegrationParams = {
farmIds: string;
};

export type PutIntegration200 = { [key: string]: unknown };

export type UpdateCropIntervalInternalIdsParams = {
catalogue: CatalogueType;
emptyCropInternalIdOnly?: boolean;
};

export type UpdateCropIntervalViaSeedApplicationsParams = {
emptyCropInternalIdOnly?: boolean;
};

export type UpdateBiomonitoringIntervalInternalIdsParams = {
catalogue: CatalogueType;
emptyCropInternalIdOnly?: boolean;
};

export type UpdateBiomonitoringIntervalViaSeedApplicationsParams = {
emptyCropInternalIdOnly?: boolean;
};

export type GeneratePreSignedURLParams = {
farmIds: string;
longitude: number;
latitude: number;
'measure-date': string;
'measure-id': string;
'file-name': string;
};

export type PostSamplesParams = {
farmIds: string;
longitude: number;
latitude: number;
'measure-date': string;
'measure-id': string;
};

export type PostSamplesBody = {
  files: Blob[];
};

export type GetMeasurementsParams = {
farmIds: string;
measurementGroupId: string;
measurementKind?: MeasurementKind;
};

export type CreateMeasurementParams = {
farmIds: string;
};

export type DeleteMeasurementByIdsParams = {
farmIds: string;
ids: string[];
};

export type AcknowledgeUploadCompletedParams = {
farmIds: string;
};

export type GetMeasurementGroupsParams = {
farmIds: string;
name?: string;
countMeasurementFrom?: number;
countMeasurementTo?: number;
lastMeasurementFrom?: string;
lastMeasurementTo?: string;
measurementKind?: MeasurementKind;
};

export type CreateMeasurementGroupParams = {
farmIds: string;
};

export type DeleteMeasurementGroupByIdsParams = {
farmIds: string;
ids: string[];
};

export type PostSamples1Params = {
'sample-year'?: {
  value?: number;
  leap?: boolean;
};
};

export type PostSamplesInSeasonParams = {
'season-id': string;
};

export type Rebuild200 = { [key: string]: unknown };

export type Notify200 = { [key: string]: unknown };

export type CreateVariodocIntegrationParams = {
farmIds: string;
};

export type CreateVariableZonesParams = {
farmIds: string;
};

export type PostAgIntegratedJobsParams = {
farmIds: string;
};

export type PostAgIntegratedJobs200 = { [key: string]: unknown };

export type GetTaskDataParams = {
farmIds: string;
language?: string;
page?: number;
'per-page'?: number;
'sort-dir'?: string;
search?: string;
showArchived?: boolean;
};

export type CreateParams = {
farmIds: string;
file: Blob[];
};

export type DeleteTaskDataParams = {
farmIds: string;
language?: string;
};

export type PatchTaskDataParams = {
farmIds: string;
language?: string;
};

export type ValidateParams = {
farmIds: string;
};

export type ValidateBody = {
  file: Blob;
};

export type DownloadBatchTaskDataParams = {
farmIds: string;
language?: string;
};

export type CreatePrescriptionDraftParams = {
farmIds: string;
};

export type AddAgroevidenceParcelsParams = {
farmIds: string;
};

export type GetMonitoringParams = {
page?: number;
'per-page'?: number;
type: string;
'crop-internal-id'?: string;
'date-from'?: string;
'date-to'?: string;
status?: string[];
farmIds: string;
};

export type AddParcelByIdParams = {
farmIds: string;
};

export type MapSatelliteParcelsParams = {
farms?: string[];
};

export type MapSatelliteParcels200 = {[key: string]: string[]};

export type LoadAeCropsToSatelliteParcelsParams = {
farms?: string[];
};

export type ReloadParcelGeometryParams = {
farms?: string[];
'update-type': GeometryUpdateType;
};

export type ReloadEarthDataImageryParams = {
farms?: string[];
parcels?: string[];
product: PrecisionFarmingProduct;
'methodology-version': MethodologyVersion;
};

export type StartBiomonitoringCalculationParams = {
farmIds: string;
'date-from': string;
};

export type ResendFarmRequests200 = {[key: string]: string[]};

export type FindInternalBiomonitoringAuditParams = {
farmIds: string;
};

export type GetMeasurementByIdParams = {
farmIds: string;
};

export type GetParcelSeasonWithMetadataParams = {
farmIds: string;
date: string;
};

export type GetColorPaletteParams = {
'crop-id'?: string;
};

export type YieldMapDetailParams = {
farmIds: string;
'season-id': string;
};

export type ValidateVariodocIntegrationParams = {
farmIds: string;
};

export type InitiateExportParams = {
farmIds: string;
'parcel-name': string;
'parcel-number': string;
'material-name': string;
'export-type': string;
};

export type InitiateExport200 = { [key: string]: unknown };

export type ExportShapeFileParams = {
farmIds: string;
'export-type'?: ExportType;
};

export type GetExportFileParams = {
farmIds: string;
};

export type GetAgIntegratedJobsParams = {
farmIds: string;
};

export type GetVariableApplicationZonesParams = {
limit?: number;
};

export type GetVariableApplicationZonesV2Params = {
'season-id'?: string;
limit?: number;
};

export type GetEnabledParcelsParams = {
farmIds: string;
};

export type GetAgIntegratedIntegrationSettingsParams = {
farmIds: string;
};

export type GetMachinesParams = {
farmIds: string;
};

export type GetVariableActionsParams = {
'action-ids'?: string[];
farmIds: string;
};

export type GetTaskData1Params = {
farmIds: string;
language?: string;
};

export type GetTaskDataTaskParams = {
farmIds: string;
language?: string;
};

export type GetTaskDataTimelineParams = {
farmIds: string;
language?: string;
attribute?: TaskAttribute;
};

export type GetTaskDataReportParams = {
farmIds: string;
language?: string;
};

export type DownloadTaskDataParams = {
farmIds: string;
language?: string;
};

export type GetSamplesParams = {
farmIds: string;
year?: string;
};

export type GetSoilSampleYearsParams = {
farmIds: string;
};

export type GetSamplesV2Params = {
farmIds: string;
'season-id'?: string;
};

export type GetSeasonParams = {
farmIds: string;
};

export type DeleteSeasonParams = {
farmIds: string;
};

export type DeleteSeason200 = { [key: string]: unknown };

export type DeleteSeason404 = { [key: string]: unknown };

export type GetParcelSeasonsParams = {
farmIds: string;
product: PrecisionFarmingProduct;
};

export type GetExternallyAvailableParcelsParams = {
farmIds: string;
'season-id'?: string;
search?: string;
crops?: string[];
};

export type GetParcelProductsParams = {
farmIds: string;
parcels: string[];
'season-id': string;
products?: PrecisionFarmingProduct[];
};

export type GetPrescriptionsParams = {
farmIds: string;
language?: string;
page?: number;
'per-page'?: number;
'sort-dir'?: string;
search?: string;
crops?: string[];
materialTypes?: MaterialType[];
products?: PrescriptionMapProduct[];
};

export type GetPrescriptionsParcelsParams = {
farmIds: string;
search?: string;
crops?: string[];
materialTypes?: MaterialType[];
products?: PrescriptionMapProduct[];
};

export type GetSatelliteParcelsParams = {
farmIds: string;
page?: number;
'per-page'?: number;
'sort-col'?: string;
'sort-dir'?: string;
language?: string;
};

export type GetParcelParams = {
farmIds: string;
};

export type GetV2SatelliteParcelsParams = {
farmIds: string;
search?: string;
};

export type GetV2SatelliteParcelParams = {
farmIds: string;
'season-id'?: string;
};

export type GetV2SatelliteParcelsUsingAeSchemaParams = {
farmIds: string;
ids?: string[];
'external-parcel-ids'?: string[];
search?: string;
};

export type GetDashboardPagedParams = {
farmIds: string;
crops?: string[];
search?: string;
'seasons-page'?: number;
'seasons-offset'?: number;
'seasons-per-page'?: number;
'seasons-sort-dir'?: string;
page?: number;
'per-page'?: number;
'sort-col'?: string;
'sort-dir'?: string;
language?: string;
'only-season-parcels'?: boolean;
};

export type GetSeasonDashboardParams = {
farmIds: string;
crops?: string[];
search?: string;
page?: number;
'per-page'?: number;
'sort-col'?: string;
'sort-dir'?: string;
language?: string;
};

export type GetCropsParams = {
farmIds: string;
};

export type GetFarmPfVersionParams = {
farmIds: string;
};

export type GetMonitoringCropIntervalsParams = {
farmIds: string;
indexType?: BiomonitoringIndexType;
};

export type GetV2SeasonBiomonitoringParams = {
'season-id'?: string;
farmIds: string;
type: string;
page?: number;
'per-page'?: number;
};

export type GetParams = {
farmIds: string;
'index-type': BiomonitoringIndexType;
'date-from': string;
'date-to': string;
};

export type GetV2SeasonInternalBiomonitoringParams = {
'season-id'?: string;
farmIds: string;
'index-type': BiomonitoringIndexType;
};

export type GetSatelliteParcelDataParams = {
localNameBlockNr?: string;
farmIds: string;
'monitoring-gisat'?: boolean;
'monitoring-planet'?: boolean;
'management-zones'?: boolean;
'growth-potential'?: boolean;
'yield-maps'?: boolean;
crops?: string[];
pageOffset?: number;
pageSize?: number;
sortProperty?: string;
language?: string;
};

export type GetFarmV2StatisticsParams = {
farmIds: string;
};

export type GetFarmStatisticsParams = {
farmIds: string;
};

export type GetCrops1Params = {
farmIds: string;
};

export type DeleteVariableApplicationParams = {
farmIds: string;
};

export type DeleteVariableApplication200 = { [key: string]: unknown };

export type DeleteAgIntegratedJobParams = {
farmIds: string;
};

export type DeleteAgIntegratedJob200 = { [key: string]: unknown };

export type DeleteParcelFromSeasonParams = {
farmIds: string;
};

export type DeleteParcelFromSeason200 = { [key: string]: unknown };

export type DeleteParcelFromSeason404 = { [key: string]: unknown };





  /**
 * @deprecated
 */
export const getAgIntegratedIntegration = <TData = AxiosResponse<IntegrationTo>>(
    params: GetAgIntegratedIntegrationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/agintegrated`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @deprecated
 */
export const enableAgIntegratedIntegration = <TData = AxiosResponse<IntegrationTo>>(
    params: EnableAgIntegratedIntegrationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/agintegrated`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const createAgIntegratedIntegration = <TData = AxiosResponse<IntegrationTo>>(
    integrationEnableTo: IntegrationEnableTo,
    params: CreateAgIntegratedIntegrationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/agintegrated`,
      integrationEnableTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @deprecated
 */
export const disableAgIntegratedIntegration = <TData = AxiosResponse<IntegrationTo>>(
    params: DisableAgIntegratedIntegrationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/agintegrated`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getVariableApplicationByActionId = <TData = AxiosResponse<VariableApplicationResponse[]>>(
    actionId: string,
    params: GetVariableApplicationByActionIdParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/actions/${actionId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const saveVariableApplication = <TData = AxiosResponse<VariableApplicationResponse[]>>(
    actionId: string,
    variableApplicationPutRequestBody: VariableApplicationPutRequestBody[],
    params: SaveVariableApplicationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/actions/${actionId}`,
      variableApplicationPutRequestBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const deleteVariableApplicationsForAction = <TData = AxiosResponse<DeleteVariableApplicationsForAction200>>(
    actionId: string,
    params: DeleteVariableApplicationsForActionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/actions/${actionId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getSeasons = <TData = AxiosResponse<SeasonTo[]>>(
    params: GetSeasonsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/seasons`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const updateSeason = <TData = AxiosResponse<UpdateSeason200>>(
    seasonTo: SeasonTo,
    params: UpdateSeasonParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/seasons`,
      seasonTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const createSeason = <TData = AxiosResponse<SeasonTo>>(
    createSeasonTo: CreateSeasonTo,
    params: CreateSeasonParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/seasons`,
      createSeasonTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const updateSeasonParcelMetadata = <TData = AxiosResponse<DashboardSeasonTo>>(
    seasonParcelMetadataTo: SeasonParcelMetadataTo,
    params: UpdateSeasonParcelMetadataParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/seasons/parcel`,
      seasonParcelMetadataTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getIntegration = <TData = AxiosResponse<IntegrationTo>>(
    params: GetIntegrationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/integrations`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const enableIntegration = <TData = AxiosResponse<IntegrationTo>>(
    params: EnableIntegrationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/integrations`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const disableIntegration = <TData = AxiosResponse<IntegrationTo>>(
    params: DisableIntegrationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/integrations`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getParcelData = <TData = AxiosResponse<ParcelDataTo[]>>(
    params: GetParcelDataParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const updateParcelsProducts = <TData = AxiosResponse<UpdateParcelsProducts200>>(
    parcelsConfigUpdateRequest: ParcelsConfigUpdateRequest,
    params: UpdateParcelsProductsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin`,
      parcelsConfigUpdateRequest,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @deprecated
 */
export const saveParcelsData = <TData = AxiosResponse<Unit>>(
    parcelConfigInsertRequest: ParcelConfigInsertRequest[],
    params: SaveParcelsDataParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin`,
      parcelConfigInsertRequest,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @deprecated
 */
export const updateParcelProducts = <TData = AxiosResponse<UpdateParcelProducts200>>(
    id: string,
    parcelConfigUpdateRequest: ParcelConfigUpdateRequest,
    params: UpdateParcelProductsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/${id}`,
      parcelConfigUpdateRequest,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const _delete = <TData = AxiosResponse<Unit>>(
    id: string,
    params: _DeleteParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/${id}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const updateV2ParcelsProducts = <TData = AxiosResponse<UpdateV2ParcelsProducts200>>(
    parcelsConfigUpdateRequest: ParcelsConfigUpdateRequest,
    params: UpdateV2ParcelsProductsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/v2/season-parcels`,
      parcelsConfigUpdateRequest,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const updateYieldMapClassification = <TData = AxiosResponse<UpdateYieldMapClassificationResponse>>(
    updateYieldMapClassificationRequest: UpdateYieldMapClassificationRequest, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/management/yield-maps/classification`,
      updateYieldMapClassificationRequest,options
    );
  }

export const reloadYieldMapClassification = <TData = AxiosResponse<AdminYieldMapReloadClassificationsResult>>(
    reloadYieldMapClassificationBody: ReloadYieldMapClassificationBody, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/management/yield-maps/classification`,
      reloadYieldMapClassificationBody,options
    );
  }

export const getIntegration1 = <TData = AxiosResponse<IntegrationAdminTo>>(
    params: GetIntegration1Params, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/integration`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const putIntegration = <TData = AxiosResponse<PutIntegration200>>(
    integrationAdminPutTo: IntegrationAdminPutTo,
    params: PutIntegrationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/integration`,
      integrationAdminPutTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const updateCropIntervalInternalIds = <TData = AxiosResponse<UpdateRecordsStatusTo>>(
    params: UpdateCropIntervalInternalIdsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/crop-interval`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const updateCropIntervalViaSeedApplications = <TData = AxiosResponse<UpdateRecordsStatusTo>>(
    params?: UpdateCropIntervalViaSeedApplicationsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/crop-interval/parcels`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const updateBiomonitoringIntervalInternalIds = <TData = AxiosResponse<UpdateRecordsStatusTo>>(
    params: UpdateBiomonitoringIntervalInternalIdsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/biomonitoring-interval`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const updateBiomonitoringIntervalViaSeedApplications = <TData = AxiosResponse<UpdateRecordsStatusTo>>(
    params?: UpdateBiomonitoringIntervalViaSeedApplicationsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/biomonitoring-interval/parcels`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const generatePreSignedURL = <TData = AxiosResponse<string>>(
    params: GeneratePreSignedURLParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/public-api/soil-samples`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const postSamples = <TData = AxiosResponse<HttpStatus>>(
    postSamplesBody: PostSamplesBody,
    params: PostSamplesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {const formData = new FormData();
postSamplesBody.files.forEach(value => formData.append('files', value));

    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/public-api/soil-samples`,
      formData,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getMeasurements = <TData = AxiosResponse<MeasurementResponseTo[]>>(
    params: GetMeasurementsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/public-api/measurements`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const createMeasurement = <TData = AxiosResponse<PresignedUrlResponseTo>>(
    measurementRequestTo: MeasurementRequestTo,
    params: CreateMeasurementParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/public-api/measurements`,
      measurementRequestTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const deleteMeasurementByIds = <TData = AxiosResponse<void>>(
    params: DeleteMeasurementByIdsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/public-api/measurements`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const acknowledgeUploadCompleted = <TData = AxiosResponse<Unit>>(
    id: string,
    params: AcknowledgeUploadCompletedParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/public-api/measurements/${id}/acknowledge-upload`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getMeasurementGroups = <TData = AxiosResponse<MeasurementGroupResponseTo[]>>(
    params: GetMeasurementGroupsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/public-api/measurement-groups`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const createMeasurementGroup = <TData = AxiosResponse<MeasurementGroupResponseTo>>(
    measurementGroupRequestTo: MeasurementGroupRequestTo,
    params: CreateMeasurementGroupParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/public-api/measurement-groups`,
      measurementGroupRequestTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const deleteMeasurementGroupByIds = <TData = AxiosResponse<void>>(
    params: DeleteMeasurementGroupByIdsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/public-api/measurement-groups`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const synchronizeParcelsAfterFarmUpdate = <TData = AxiosResponse<void>>(
    farmId: string,
    farmUpdateResultTo: FarmUpdateResultTo, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/internal-api/update-sync/${farmId}`,
      farmUpdateResultTo,options
    );
  }

/**
 * @deprecated
 */
export const postSamples1 = <TData = AxiosResponse<SamplesUploadResult>>(
    soilSamplesRequestBody: SoilSamplesRequestBody,
    params?: PostSamples1Params, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/internal-api/soil-samples`,
      soilSamplesRequestBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const postSamplesInSeason = <TData = AxiosResponse<SamplesUploadResult>>(
    soilSamplesRequestBody: SoilSamplesRequestBody,
    params: PostSamplesInSeasonParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/internal-api/soil-samples/v2`,
      soilSamplesRequestBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const onSeedChanged = <TData = AxiosResponse<void>>(
    parcelId: string[], options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/internal-api/seed-change/${parcelId}`,undefined,options
    );
  }

export const save = <TData = AxiosResponse<void>>(
    measurementPredictionRequest: MeasurementPredictionRequest, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/internal-api/measurements/predictions`,
      measurementPredictionRequest,options
    );
  }

export const rebuild = <TData = AxiosResponse<Rebuild200>>(
    parcelId: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/internal-api/biophysical-monitoring/reload/${parcelId}`,undefined,options
    );
  }

export const reloadAll = <TData = AxiosResponse<ReloadResultTo>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/internal-api/biophysical-monitoring/reload-all`,undefined,options
    );
  }

export const notify = <TData = AxiosResponse<Notify200>>(
    parcelId: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/internal-api/biophysical-monitoring/notify/${parcelId}`,undefined,options
    );
  }

export const createVariodocIntegration = <TData = AxiosResponse<IntegrationTo>>(
    variodocCreateTo: VariodocCreateTo,
    params: CreateVariodocIntegrationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variodoc`,
      variodocCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const createVariableZones = <TData = AxiosResponse<VariableApplicationResponse>>(
    params: CreateVariableZonesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const postAgIntegratedJobs = <TData = AxiosResponse<PostAgIntegratedJobs200>>(
    applicationId: string,
    jobId: number,
    machinesUploadRequestTo: MachinesUploadRequestTo,
    params: PostAgIntegratedJobsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/${applicationId}/agintegrated-jobs/${jobId}/machine`,
      machinesUploadRequestTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getTaskData = <TData = AxiosResponse<TaskDataListTo[]>>(
    params: GetTaskDataParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/taskdata`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Upload multiple isoxml files for processing
 */
export const create = <TData = AxiosResponse<TaskDataImportResponseTo>>(
    params: CreateParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/taskdata`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const deleteTaskData = <TData = AxiosResponse<void>>(
    taskDataDeleteTo: TaskDataDeleteTo,
    params: DeleteTaskDataParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/taskdata`,{data:
      taskDataDeleteTo, 
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const patchTaskData = <TData = AxiosResponse<void>>(
    taskDataPatchTo: TaskDataPatchTo,
    params: PatchTaskDataParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/taskdata`,
      taskDataPatchTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Validate isoxml file before processing
 */
export const validate = <TData = AxiosResponse<TaskDataImportResponseFileTo>>(
    validateBody: ValidateBody,
    params: ValidateParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/taskdata/validate`,
      validateBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const downloadBatchTaskData = <TData = AxiosResponse<void>>(
    taskDataDownloadTo: TaskDataDownloadTo,
    params: DownloadBatchTaskDataParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/taskdata/download`,
      taskDataDownloadTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const createPrescriptionDraft = <TData = AxiosResponse<PrescriptionDraftCreateResponse>>(
    prescriptionDraftCreateRequest: PrescriptionDraftCreateRequest,
    params: CreatePrescriptionDraftParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/prescription-maps/draft`,
      prescriptionDraftCreateRequest,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const addAgroevidenceParcels = <TData = AxiosResponse<DashboardParcelTo[]>>(
    addAgroevidenceParcelsTo: AddAgroevidenceParcelsTo,
    params: AddAgroevidenceParcelsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/precision-parcels/add-ae-parcels`,
      addAgroevidenceParcelsTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getMonitoring = <TData = AxiosResponse<BiomonitoringIntervalResponse[]>>(
    parcelId: string,
    params: GetMonitoringParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/biophysical-monitoring/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const addParcelById = <TData = AxiosResponse<BiomonitoringResponseStatus>>(
    parcelId: string,
    params: AddParcelByIdParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/biophysical-monitoring/${parcelId}`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const mapSatelliteParcels = <TData = AxiosResponse<MapSatelliteParcels200>>(
    params?: MapSatelliteParcelsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/migration/map-ae-satellite-parcels`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const loadAeCropsToSatelliteParcels = <TData = AxiosResponse<void>>(
    params?: LoadAeCropsToSatelliteParcelsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/migration/load-ae-crop-to-parcel-metadata`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const saveFarmPfVersion = <TData = AxiosResponse<void>>(
    farmPfVersionTo: FarmPfVersionTo[], options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/migration/farm-pf-version`,
      farmPfVersionTo,options
    );
  }

export const reloadParcelGeometry = <TData = AxiosResponse<void>>(
    params: ReloadParcelGeometryParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/management/reload-parcel-geometry`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const reloadEarthDataImagery = <TData = AxiosResponse<void>>(
    params: ReloadEarthDataImageryParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/management/reload-ed-imagery`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const fillFarmCatalogue = <TData = AxiosResponse<void>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/management/fill-farm-catalogue`,undefined,options
    );
  }

export const createManagementZoneAudit = <TData = AxiosResponse<void>>(
    managementZoneAuditDto: ManagementZoneAuditDto[], options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/management-zones`,
      managementZoneAuditDto,options
    );
  }

export const startManagementZonesCalculation = <TData = AxiosResponse<void>>(
    farmId: string,
    parcelId: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/management-zones/${farmId}/${parcelId}`,undefined,options
    );
  }

export const startBiomonitoringCalculation = <TData = AxiosResponse<void>>(
    parcelId: string,
    params: StartBiomonitoringCalculationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/biomonitoring/${parcelId}`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const resendRequests = <TData = AxiosResponse<string[]>>(
    parcelIds: string[], options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/biomonitoring/seed-change/${parcelIds}`,undefined,options
    );
  }

export const resendFarmRequests = <TData = AxiosResponse<ResendFarmRequests200>>(
    farms: string[], options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/biomonitoring/seed-change-farms/${farms}`,undefined,options
    );
  }

export const findInternalBiomonitoringAudit = <TData = AxiosResponse<InternalBiomonitoringAudit[]>>(
    params: FindInternalBiomonitoringAuditParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/biomonitoring/audit`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const createInternalBiomonitoringAudit = <TData = AxiosResponse<void>>(
    internalBiomonitoringAuditTo: InternalBiomonitoringAuditTo[], options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/biomonitoring/audit`,
      internalBiomonitoringAuditTo,options
    );
  }

export const getMeasurementById = <TData = AxiosResponse<MeasurementResponseTo>>(
    id: string,
    params: GetMeasurementByIdParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/public-api/measurements/${id}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getYieldMapClassificationOverview = <TData = AxiosResponse<string>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/internal/web/yield-maps`,options
    );
  }

export const getParcelSeasonWithMetadata = <TData = AxiosResponse<DashboardSeasonTo[]>>(
    parcelId: string,
    params: GetParcelSeasonWithMetadataParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/internal-api/seasons/metadata/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getColorPalette = <TData = AxiosResponse<InternalColorPaletteResponse>>(
    product: PrecisionFarmingProduct,
    params?: GetColorPaletteParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/internal-api/color-palettes/${product}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const yieldMapDetail = <TData = AxiosResponse<YieldMapDetailResponse>>(
    parcelId: string,
    params: YieldMapDetailParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/yield-maps/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const validateVariodocIntegration = <TData = AxiosResponse<Unit>>(
    params: ValidateVariodocIntegrationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variodoc/validate`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const initiateExport = <TData = AxiosResponse<InitiateExport200>>(
    applicationId: string,
    params: InitiateExportParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/${applicationId}/export`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const exportShapeFile = <TData = AxiosResponse<Blob>>(
    applicationId: string,
    params: ExportShapeFileParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/${applicationId}/export/legacy`,{
        responseType: 'blob',
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getExportFile = <TData = AxiosResponse<string>>(
    applicationId: string,
    jobId: number,
    params: GetExportFileParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/${applicationId}/download-file/${jobId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getAgIntegratedJobs = <TData = AxiosResponse<AgIntegratedJobsTo[]>>(
    applicationId: string,
    params: GetAgIntegratedJobsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/${applicationId}/agintegrated-jobs`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns the newest list of variable action zones.The limit query parameter controls how many data are returned and defaults to 5 if no value is passed in.
 * @summary Returns a list of variable action zones. This is the preferred way of retrieving VA zones, although be aware that not all products are available yet.
 */
export const getVariableApplicationZones = <TData = AxiosResponse<VariableActionZonesTo[]>>(
    farmId: string,
    parcelId: string,
    params?: GetVariableApplicationZonesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/zones/${farmId}/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getVariableApplicationZonesV2 = <TData = AxiosResponse<VariableActionZonesTo[]>>(
    farmId: string,
    parcelId: string,
    params?: GetVariableApplicationZonesV2Params, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/v2/zones/${farmId}/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getEnabledParcels = <TData = AxiosResponse<string[]>>(
    params: GetEnabledParcelsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/parcels`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getAgIntegratedIntegrationSettings = <TData = AxiosResponse<IntegrationSettingsTo>>(
    params: GetAgIntegratedIntegrationSettingsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/agintegrated/settings`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getMachines = <TData = AxiosResponse<MachineTo[]>>(
    params: GetMachinesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/agintegrated/machine`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getVariableActions = <TData = AxiosResponse<string[]>>(
    params: GetVariableActionsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/actions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getTaskData1 = <TData = AxiosResponse<TaskDataListTo>>(
    id: number,
    params: GetTaskData1Params, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/taskdata/${id}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getTaskDataTask = <TData = AxiosResponse<TaskDataTaskTo>>(
    id: number,
    taskId: string,
    params: GetTaskDataTaskParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/taskdata/${id}/${taskId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getTaskDataTimeline = <TData = AxiosResponse<TaskDataTimelineTo[]>>(
    id: number,
    taskId: string,
    params: GetTaskDataTimelineParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/taskdata/${id}/${taskId}/timeline`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getTaskDataReport = <TData = AxiosResponse<TaskDataReportTo>>(
    id: number,
    taskId: string,
    params: GetTaskDataReportParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/taskdata/${id}/${taskId}/report`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const downloadTaskData = <TData = AxiosResponse<string>>(
    id: number,
    params: DownloadTaskDataParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/taskdata/${id}/download`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns list of soil samples and their respective zoning for the given year.If year request parameter is not specified, returns soil samples applicable for today.All geometries must be returned in Web Mercator CRS, i.e. 3857.
 * @deprecated
 * @summary Returns soil samples
 */
export const getSamples = <TData = AxiosResponse<SoilSamplesResponse[]>>(
    parcelId: string,
    params: GetSamplesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/soil-samples/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @deprecated
 * @summary Returns all years for which we have some samples at the given parcel.
 */
export const getSoilSampleYears = <TData = AxiosResponse<number[]>>(
    parcelId: string,
    params: GetSoilSampleYearsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/soil-samples/${parcelId}/years`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getSamplesV2 = <TData = AxiosResponse<SoilSamplesResponse[]>>(
    parcelId: string,
    params: GetSamplesV2Params, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/soil-samples/v2/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getSeason = <TData = AxiosResponse<SeasonTo>>(
    seasonId: string,
    params: GetSeasonParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/seasons/${seasonId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const deleteSeason = <TData = AxiosResponse<DeleteSeason200>>(
    seasonId: string,
    params: DeleteSeasonParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/seasons/${seasonId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getParcelSeasons = <TData = AxiosResponse<SeasonWithCropTo[]>>(
    parcelId: string,
    params: GetParcelSeasonsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/seasons/parcel/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getExternallyAvailableParcels = <TData = AxiosResponse<ExternalParcelTo[]>>(
    params: GetExternallyAvailableParcelsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/seasons/external-available-parcels`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getParcelProducts = <TData = AxiosResponse<AvailableParcelProduct[]>>(
    params: GetParcelProductsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/products`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getParcelProductStatus = <TData = AxiosResponse<ParcelProductTo>>(
    parcelId: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/products/${parcelId}`,options
    );
  }

export const getPrescriptions = <TData = AxiosResponse<PrescriptionMapPageResponse>>(
    params: GetPrescriptionsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/prescription-maps`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getPrescriptionsParcels = <TData = AxiosResponse<PrescriptionMapParcelResponse[]>>(
    id: string,
    params: GetPrescriptionsParcelsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/prescription-maps/${id}/parcels`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getSatelliteParcels = <TData = AxiosResponse<SatelliteParcel[]>>(
    params: GetSatelliteParcelsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/precision-parcels`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getParcel = <TData = AxiosResponse<SatelliteParcel>>(
    parcelId: string,
    params: GetParcelParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/precision-parcels/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getV2SatelliteParcels = <TData = AxiosResponse<InternalDashboardParcel[]>>(
    params: GetV2SatelliteParcelsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/precision-parcels/v2`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getV2SatelliteParcel = <TData = AxiosResponse<SatelliteParcelV2>>(
    parcelId: string,
    params: GetV2SatelliteParcelParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/precision-parcels/v2/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getV2SatelliteParcelsUsingAeSchema = <TData = AxiosResponse<AeParcelV2To[]>>(
    params: GetV2SatelliteParcelsUsingAeSchemaParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/precision-parcels/v2/ae-schema`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getDashboardPaged = <TData = AxiosResponse<SeasonsDashboardTo>>(
    params: GetDashboardPagedParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/parcel/dashboard`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getSeasonDashboard = <TData = AxiosResponse<DashboardSeasonTo[]>>(
    seasonId: string,
    params: GetSeasonDashboardParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/parcel/dashboard/${seasonId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getCrops = <TData = AxiosResponse<InternalCropTo[]>>(
    params: GetCropsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/parcel/dashboard/crops`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getFarmPfVersion = <TData = AxiosResponse<FarmPfVersionEnum>>(
    params: GetFarmPfVersionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/migration`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getManagementZones = <TData = AxiosResponse<ManagementZoneResponseDto[]>>(
    farmId: string,
    parcelId: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/management-zones/${farmId}/${parcelId}`,options
    );
  }

export const getMonitoringCropIntervals = <TData = AxiosResponse<CropIntervalTo[]>>(
    parcelId: string,
    params: GetMonitoringCropIntervalsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/biophysical-monitoring/${parcelId}/crop-intervals`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getV2SeasonBiomonitoring = <TData = AxiosResponse<BiomonitoringIntervalResponse[]>>(
    parcelId: string,
    params: GetV2SeasonBiomonitoringParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/biophysical-monitoring/v2/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const get = <TData = AxiosResponse<BiomonitoringIndexRecordsDto>>(
    parcelId: string,
    params: GetParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/biomonitoring/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getV2SeasonInternalBiomonitoring = <TData = AxiosResponse<BiomonitoringIndexRecordsDto>>(
    parcelId: string,
    params: GetV2SeasonInternalBiomonitoringParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/biomonitoring/v2/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getSatelliteParcelData = <TData = AxiosResponse<ParcelDataTo[]>>(
    params: GetSatelliteParcelDataParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/v2`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getFarmV2Statistics = <TData = AxiosResponse<FarmStatistics>>(
    params: GetFarmV2StatisticsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/v2/statistics`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getFarmStatistics = <TData = AxiosResponse<FarmStatistics>>(
    params: GetFarmStatisticsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/statistics`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const findManagementZoneAudit = <TData = AxiosResponse<ManagementZoneAudit[]>>(
    farmId: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/management-zones/${farmId}`,options
    );
  }

export const getCrops1 = <TData = AxiosResponse<InternalCropTo[]>>(
    params: GetCrops1Params, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/admin/crops`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const deleteVariableApplication = <TData = AxiosResponse<DeleteVariableApplication200>>(
    applicationId: string,
    params: DeleteVariableApplicationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/${applicationId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const deleteAgIntegratedJob = <TData = AxiosResponse<DeleteAgIntegratedJob200>>(
    jobId: number,
    params: DeleteAgIntegratedJobParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/variable-applications/agintegrated-jobs/${jobId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const deleteParcelFromSeason = <TData = AxiosResponse<DeleteParcelFromSeason200>>(
    seasonId: string,
    parcelId: string,
    params: DeleteParcelFromSeasonParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://cf-satellite.cf-nightly.dev.cleverfarm.cz/api/seasons/${seasonId}/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export type GetAgIntegratedIntegrationResult = AxiosResponse<IntegrationTo>
export type EnableAgIntegratedIntegrationResult = AxiosResponse<IntegrationTo>
export type CreateAgIntegratedIntegrationResult = AxiosResponse<IntegrationTo>
export type DisableAgIntegratedIntegrationResult = AxiosResponse<IntegrationTo>
export type GetVariableApplicationByActionIdResult = AxiosResponse<VariableApplicationResponse[]>
export type SaveVariableApplicationResult = AxiosResponse<VariableApplicationResponse[]>
export type DeleteVariableApplicationsForActionResult = AxiosResponse<DeleteVariableApplicationsForAction200>
export type GetSeasonsResult = AxiosResponse<SeasonTo[]>
export type UpdateSeasonResult = AxiosResponse<UpdateSeason200>
export type CreateSeasonResult = AxiosResponse<SeasonTo>
export type UpdateSeasonParcelMetadataResult = AxiosResponse<DashboardSeasonTo>
export type GetIntegrationResult = AxiosResponse<IntegrationTo>
export type EnableIntegrationResult = AxiosResponse<IntegrationTo>
export type DisableIntegrationResult = AxiosResponse<IntegrationTo>
export type GetParcelDataResult = AxiosResponse<ParcelDataTo[]>
export type UpdateParcelsProductsResult = AxiosResponse<UpdateParcelsProducts200>
export type SaveParcelsDataResult = AxiosResponse<Unit>
export type UpdateParcelProductsResult = AxiosResponse<UpdateParcelProducts200>
export type _DeleteResult = AxiosResponse<Unit>
export type UpdateV2ParcelsProductsResult = AxiosResponse<UpdateV2ParcelsProducts200>
export type UpdateYieldMapClassificationResult = AxiosResponse<UpdateYieldMapClassificationResponse>
export type ReloadYieldMapClassificationResult = AxiosResponse<AdminYieldMapReloadClassificationsResult>
export type GetIntegration1Result = AxiosResponse<IntegrationAdminTo>
export type PutIntegrationResult = AxiosResponse<PutIntegration200>
export type UpdateCropIntervalInternalIdsResult = AxiosResponse<UpdateRecordsStatusTo>
export type UpdateCropIntervalViaSeedApplicationsResult = AxiosResponse<UpdateRecordsStatusTo>
export type UpdateBiomonitoringIntervalInternalIdsResult = AxiosResponse<UpdateRecordsStatusTo>
export type UpdateBiomonitoringIntervalViaSeedApplicationsResult = AxiosResponse<UpdateRecordsStatusTo>
export type GeneratePreSignedURLResult = AxiosResponse<string>
export type PostSamplesResult = AxiosResponse<HttpStatus>
export type GetMeasurementsResult = AxiosResponse<MeasurementResponseTo[]>
export type CreateMeasurementResult = AxiosResponse<PresignedUrlResponseTo>
export type DeleteMeasurementByIdsResult = AxiosResponse<void>
export type AcknowledgeUploadCompletedResult = AxiosResponse<Unit>
export type GetMeasurementGroupsResult = AxiosResponse<MeasurementGroupResponseTo[]>
export type CreateMeasurementGroupResult = AxiosResponse<MeasurementGroupResponseTo>
export type DeleteMeasurementGroupByIdsResult = AxiosResponse<void>
export type SynchronizeParcelsAfterFarmUpdateResult = AxiosResponse<void>
export type PostSamples1Result = AxiosResponse<SamplesUploadResult>
export type PostSamplesInSeasonResult = AxiosResponse<SamplesUploadResult>
export type OnSeedChangedResult = AxiosResponse<void>
export type SaveResult = AxiosResponse<void>
export type RebuildResult = AxiosResponse<Rebuild200>
export type ReloadAllResult = AxiosResponse<ReloadResultTo>
export type NotifyResult = AxiosResponse<Notify200>
export type CreateVariodocIntegrationResult = AxiosResponse<IntegrationTo>
export type CreateVariableZonesResult = AxiosResponse<VariableApplicationResponse>
export type PostAgIntegratedJobsResult = AxiosResponse<PostAgIntegratedJobs200>
export type GetTaskDataResult = AxiosResponse<TaskDataListTo[]>
export type CreateResult = AxiosResponse<TaskDataImportResponseTo>
export type DeleteTaskDataResult = AxiosResponse<void>
export type PatchTaskDataResult = AxiosResponse<void>
export type ValidateResult = AxiosResponse<TaskDataImportResponseFileTo>
export type DownloadBatchTaskDataResult = AxiosResponse<void>
export type CreatePrescriptionDraftResult = AxiosResponse<PrescriptionDraftCreateResponse>
export type AddAgroevidenceParcelsResult = AxiosResponse<DashboardParcelTo[]>
export type GetMonitoringResult = AxiosResponse<BiomonitoringIntervalResponse[]>
export type AddParcelByIdResult = AxiosResponse<BiomonitoringResponseStatus>
export type MapSatelliteParcelsResult = AxiosResponse<MapSatelliteParcels200>
export type LoadAeCropsToSatelliteParcelsResult = AxiosResponse<void>
export type SaveFarmPfVersionResult = AxiosResponse<void>
export type ReloadParcelGeometryResult = AxiosResponse<void>
export type ReloadEarthDataImageryResult = AxiosResponse<void>
export type FillFarmCatalogueResult = AxiosResponse<void>
export type CreateManagementZoneAuditResult = AxiosResponse<void>
export type StartManagementZonesCalculationResult = AxiosResponse<void>
export type StartBiomonitoringCalculationResult = AxiosResponse<void>
export type ResendRequestsResult = AxiosResponse<string[]>
export type ResendFarmRequestsResult = AxiosResponse<ResendFarmRequests200>
export type FindInternalBiomonitoringAuditResult = AxiosResponse<InternalBiomonitoringAudit[]>
export type CreateInternalBiomonitoringAuditResult = AxiosResponse<void>
export type GetMeasurementByIdResult = AxiosResponse<MeasurementResponseTo>
export type GetYieldMapClassificationOverviewResult = AxiosResponse<string>
export type GetParcelSeasonWithMetadataResult = AxiosResponse<DashboardSeasonTo[]>
export type GetColorPaletteResult = AxiosResponse<InternalColorPaletteResponse>
export type YieldMapDetailResult = AxiosResponse<YieldMapDetailResponse>
export type ValidateVariodocIntegrationResult = AxiosResponse<Unit>
export type InitiateExportResult = AxiosResponse<InitiateExport200>
export type ExportShapeFileResult = AxiosResponse<Blob>
export type GetExportFileResult = AxiosResponse<string>
export type GetAgIntegratedJobsResult = AxiosResponse<AgIntegratedJobsTo[]>
export type GetVariableApplicationZonesResult = AxiosResponse<VariableActionZonesTo[]>
export type GetVariableApplicationZonesV2Result = AxiosResponse<VariableActionZonesTo[]>
export type GetEnabledParcelsResult = AxiosResponse<string[]>
export type GetAgIntegratedIntegrationSettingsResult = AxiosResponse<IntegrationSettingsTo>
export type GetMachinesResult = AxiosResponse<MachineTo[]>
export type GetVariableActionsResult = AxiosResponse<string[]>
export type GetTaskData1Result = AxiosResponse<TaskDataListTo>
export type GetTaskDataTaskResult = AxiosResponse<TaskDataTaskTo>
export type GetTaskDataTimelineResult = AxiosResponse<TaskDataTimelineTo[]>
export type GetTaskDataReportResult = AxiosResponse<TaskDataReportTo>
export type DownloadTaskDataResult = AxiosResponse<string>
export type GetSamplesResult = AxiosResponse<SoilSamplesResponse[]>
export type GetSoilSampleYearsResult = AxiosResponse<number[]>
export type GetSamplesV2Result = AxiosResponse<SoilSamplesResponse[]>
export type GetSeasonResult = AxiosResponse<SeasonTo>
export type DeleteSeasonResult = AxiosResponse<DeleteSeason200>
export type GetParcelSeasonsResult = AxiosResponse<SeasonWithCropTo[]>
export type GetExternallyAvailableParcelsResult = AxiosResponse<ExternalParcelTo[]>
export type GetParcelProductsResult = AxiosResponse<AvailableParcelProduct[]>
export type GetParcelProductStatusResult = AxiosResponse<ParcelProductTo>
export type GetPrescriptionsResult = AxiosResponse<PrescriptionMapPageResponse>
export type GetPrescriptionsParcelsResult = AxiosResponse<PrescriptionMapParcelResponse[]>
export type GetSatelliteParcelsResult = AxiosResponse<SatelliteParcel[]>
export type GetParcelResult = AxiosResponse<SatelliteParcel>
export type GetV2SatelliteParcelsResult = AxiosResponse<InternalDashboardParcel[]>
export type GetV2SatelliteParcelResult = AxiosResponse<SatelliteParcelV2>
export type GetV2SatelliteParcelsUsingAeSchemaResult = AxiosResponse<AeParcelV2To[]>
export type GetDashboardPagedResult = AxiosResponse<SeasonsDashboardTo>
export type GetSeasonDashboardResult = AxiosResponse<DashboardSeasonTo[]>
export type GetCropsResult = AxiosResponse<InternalCropTo[]>
export type GetFarmPfVersionResult = AxiosResponse<FarmPfVersionEnum>
export type GetManagementZonesResult = AxiosResponse<ManagementZoneResponseDto[]>
export type GetMonitoringCropIntervalsResult = AxiosResponse<CropIntervalTo[]>
export type GetV2SeasonBiomonitoringResult = AxiosResponse<BiomonitoringIntervalResponse[]>
export type GetResult = AxiosResponse<BiomonitoringIndexRecordsDto>
export type GetV2SeasonInternalBiomonitoringResult = AxiosResponse<BiomonitoringIndexRecordsDto>
export type GetSatelliteParcelDataResult = AxiosResponse<ParcelDataTo[]>
export type GetFarmV2StatisticsResult = AxiosResponse<FarmStatistics>
export type GetFarmStatisticsResult = AxiosResponse<FarmStatistics>
export type FindManagementZoneAuditResult = AxiosResponse<ManagementZoneAudit[]>
export type GetCrops1Result = AxiosResponse<InternalCropTo[]>
export type DeleteVariableApplicationResult = AxiosResponse<DeleteVariableApplication200>
export type DeleteAgIntegratedJobResult = AxiosResponse<DeleteAgIntegratedJob200>
export type DeleteParcelFromSeasonResult = AxiosResponse<DeleteParcelFromSeason200>
