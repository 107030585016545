import React from "react";

import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

interface Props {
  children: React.ReactNode;
  callback: React.MouseEventHandler<HTMLButtonElement>;
  classes: Record<string, string>;
  disabled?: boolean;
  farm?: { id: string; name: string };
  testId: string;
}

const ImpersonationButton = ({
  callback,
  children,
  classes: propClasses,
  disabled = false,
  farm,
  testId,
}: Props) => {
  const classes = useStyles();

  return (
    <Button
      aria-label="impersonation button"
      data-test={testId}
      disabled={disabled}
      onClick={(evt) => callback(evt)}
      classes={{
        root: classNames(propClasses.root, farm && classes.rootActive),
      }}
    >
      {children}
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    background: theme.palette.background.paper,
  },
  rootActive: {
    color: theme.palette.background.paper,
    background: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

export { ImpersonationButton };
