import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  getMonitoringCropIntervalsIsFetching,
  getMonitoringDataIsFetching,
  getRecordsValidity,
} from "../../../../shared/api/sentinel/monitoring/monitoring.selectors";
import {
  getChartData,
  getCropIntervals,
  getDateFrom,
  getDateTo,
  getIndex,
  getMonitoringData,
  getMonitoringStatus,
  getSelectedIntervalId,
} from "../../selectors/monitoring.selectors";

import * as satelliteProductsTypes from "../../../../shared/constants/satelliteProductsTypes.constants";

import {
  getCropIntervalsApi,
  getMonitoringDataApi,
} from "../../../../shared/api/sentinel/monitoring/monitoring.api";
import {
  disableHTMLCanvasImageSmoothing,
  enableHTMLCanvasImageSmoothing,
} from "../../../../shared/misc/helpers";
import { BioMonitoringHistoryInfoDialog } from "../../components/BioMonitoring/BioMonitoringHistoryInfoDialog";
import { MONITORING_STATUS } from "../../selectors/monitoringStatus";
import BioMonitoringImageErrorService from "../../services/BioMonitoringImageError.services";

import { isPlanetIndexType } from "./BiomonitoringWrapper/helpers";
import { IndexTypeDataType } from "./types";
import { useLocking } from "./useLocking";

const supportedImageStates = [
  MONITORING_STATUS.OK,
  MONITORING_STATUS.NO_ZONES,
  MONITORING_STATUS.NO_ZONES_2020,
  MONITORING_STATUS.LAI_ONLY,
];

const useGisatBiomonitoring = (parcelId: string) => {
  const dispatch = useDispatch();

  const index = useSelector(getIndex);
  const isFetchingData = useSelector(getMonitoringDataIsFetching);
  const isFetchingCropIntervals = useSelector(
    getMonitoringCropIntervalsIsFetching,
  );
  const dateFrom = useSelector(getDateFrom);
  const dateTo = useSelector(getDateTo);
  const monitoringStatus = useSelector(getMonitoringStatus);
  const data = useSelector(getMonitoringData);
  const graphData = useSelector(getChartData);
  const validity = useSelector(getRecordsValidity);

  const cropIntervals = useSelector(getCropIntervals);
  const selectedIntervalId = useSelector(getSelectedIntervalId);

  const { isInit } = useLocking({
    isInitCustomCondition: !isPlanetIndexType(index),
    cropIntervals,
    selectedIntervalId,
    isFetchingCropIntervals,
  });

  const isFetching = isFetchingData || isFetchingCropIntervals;

  useEffect(() => {
    if (!isInit) {
      disableHTMLCanvasImageSmoothing();
      if (
        monitoringStatus === satelliteProductsTypes.ACTIVE ||
        monitoringStatus === satelliteProductsTypes.HISTORICAL
      ) {
        dispatch(getCropIntervalsApi(parcelId));
      }
    }
    return () => {
      enableHTMLCanvasImageSmoothing();
    };
  }, [dispatch, isInit, monitoringStatus, parcelId]);

  useEffect(() => {
    if (isInit && dateFrom) {
      const status = supportedImageStates.join(",");
      dispatch(getMonitoringDataApi(parcelId, index, dateFrom, dateTo, status));
    }
  }, [dateFrom, parcelId, dateTo, dispatch, isInit, index]);

  const overallImagesError =
    BioMonitoringImageErrorService.getOverallImagesError(data, index);
  const cropHarvestedError =
    BioMonitoringImageErrorService.checkCropHarvestedError(data);
  const lastImageDateTo = validity;

  const infoDialogContent = (
    <BioMonitoringHistoryInfoDialog.Root index={index} />
  );

  return {
    isFetching,
    overallImagesError,
    cropHarvestedError,
    lastImageDateTo,
    data,
    infoDialogContent,
    graphData,
  } as IndexTypeDataType;
};

export { useGisatBiomonitoring };
