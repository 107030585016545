import React from "react";

import { LANGUAGE_ID, LANG_KEYS } from "../../../../shared/lang/lang.constants";

import { ImpersonationPanel } from "../../../../admin/impersonation/ImpersonationPanel";
import ImpersonationWrapper from "../../../../admin/impersonation/ImpersonationWrapper";
import BaseContainer from "../../../../shared/containers/BaseContainer/BaseContainer";
import { useToggle } from "../../../../shared/hooks/useToggle";
import { JwtService } from "../../../../shared/services/Jwt.service";
import { Navbar } from "../../components/Navbar/Navbar";
import translationsCZ from "../../lang/locale-cs-CZ.json";
import translationsEL from "../../lang/locale-el-GR.json";
import translationsEN from "../../lang/locale-en-US.json";
import translationsES from "../../lang/locale-es-ES.json";
import translationsHU from "../../lang/locale-hu-HU.json";
import translationsPL from "../../lang/locale-pl-PL.json";
import translationsRO from "../../lang/locale-ro-RO.json";

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.HU]: translationsHU,
  [LANG_KEYS.PL]: translationsPL,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.EL]: translationsEL,
  [LANG_KEYS.RO]: translationsRO,
};

type Props = {
  ngProps: {
    ngImpersEnable: () => void;
    langId: LANGUAGE_ID;
    productFruitsId: string;
    user: Record<string, unknown>;
    farm: {
      id: string;
      name: string;
    };
    farmSettings: {
      eagriSettingsEnabled: boolean;
    };
  };
};

const NavbarWrapper = (props: React.PropsWithChildren<Props>) => {
  const { ngProps } = props;
  const impersonation = useToggle(JwtService.isImpersonatedToken());

  return (
    <BaseContainer langId={ngProps.langId} translations={translations}>
      {props.children}
      <Navbar ngProps={ngProps} onImpersonationToggle={impersonation.toggle} />
      {impersonation.on ? (
        <ImpersonationWrapper langId={ngProps.langId}>
          <ImpersonationPanel
            farm={ngProps.farm}
            toggle={impersonation.toggle}
          />
        </ImpersonationWrapper>
      ) : null}
    </BaseContainer>
  );
};

export { NavbarWrapper };
