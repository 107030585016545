import React from "react";

import { BioMonitoring } from "../../BioMonitoring";
import { IndexTypeDataProps } from "../types";
import { useGisatBiomonitoring } from "../useGisatBiomonitoring";

const GisatSection = ({
  index,
  parcelId,
  setCropHarvestedError,
}: IndexTypeDataProps) => {
  const hookData = useGisatBiomonitoring(parcelId);

  React.useEffect(() => {
    if (hookData && !hookData?.isFetching) {
      if (hookData.cropHarvestedError) {
        setCropHarvestedError(hookData.cropHarvestedError);
      }
    }
  }, [hookData, hookData?.isFetching, setCropHarvestedError]);

  return (
    <BioMonitoring
      hookData={hookData}
      index={index}
      lastUpdate={undefined}
      parcelId={parcelId}
    />
  );
};

export { GisatSection };
