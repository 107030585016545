import React from "react";

import moment from "moment";
import { createRoot } from "react-dom/client";

import reducer from "../../reducers/precision.reducer";

import CfApp from "../../shared/containers/CfApp/CfApp";
import Localization from "../../shared/services/Localization.service";

import PrecisionWrapper from "./containers/PrecisionWrapper";

PrecisionController.$inject = [
  "$element",
  "$scope",
  "$state",
  "$rootScope",
  "$transitions",
  "BACKEND_OPTIONS",
  "ENVIRONMENT",
  "Auth",
  "ResponseErrorInterceptor",
];

export default function PrecisionController(
  $element,
  $scope,
  $state,
  $rootScope,
  $transitions,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Auth,
  ResponseErrorInterceptor,
) {
  let root;
  const props = {
    farm: this.farm,
    farmId: this.farm.id,
    ngRedirectToSowing,
    ngRedirectToVrf,
    ngRedirectToVrs,
    ngRedirectToUserIntegrations,
    reducer,
    currState: $state.current.name,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    apiProps: {
      farmId: this.farm.id,
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
    },
    cacheKey: "precision",
  };

  const unlistenTranslateChange = $rootScope.$on(
    "$translateChangeSuccess",
    () => {
      renderWithLang();
    },
  );

  const deregisterOnTransitionSuccess = $transitions.onBefore(
    {},
    (transition) => {
      // hack because of behaviour of ui-router vs react-router
      // we need to block transition inside parcels - when we want to switch farm, it is superseded
      const precisionState = "farm.active.precision";
      const transitionFrom = transition.from();
      const transitionTo = transition.to();
      const transitionParams = transition.params();
      if (
        transitionFrom.name.startsWith(precisionState) &&
        transitionTo.name.startsWith(precisionState) &&
        transitionParams.farmId === this.farm.id
      ) {
        transition.abort();
      }
    },
  );

  $scope.$on("$destroy", () => {
    unlistenTranslateChange();
    deregisterOnTransitionSuccess();
    root.unmount();
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  const renderWithLang = () => {
    const langId = Localization.getLang();
    moment.locale(langId);
    render();
  };

  function ngRedirectToSowing(parcelIds) {
    $state.go("farm.active.actions.sowing", { parcelIds });
  }

  function ngRedirectToVrf(parcelIds) {
    return $state.go("farm.active.actions.vrf", { parcelIds });
  }

  function ngRedirectToVrs(parcelIds) {
    return $state.go("farm.active.actions.vrs", { parcelIds });
  }

  function ngRedirectToUserIntegrations(farmId) {
    return $state.go("farm.active.user.integrations", { farmId });
  }

  const render = () => {
    $element[0].click();
    root = createRoot($element[0]);
    root.render(
      <CfApp {...props} render={(p) => <PrecisionWrapper {...p} />} />,
    );
  };
}
