import React from "react";

import moment from "moment";
import { createRoot } from "react-dom/client";

import reducer from "../reducers/telematics.reducer";

import CfApp from "../shared/containers/CfApp/CfApp";
import Localization from "../shared/services/Localization.service";
import UiCacheService from "../shared/services/UiCache.service";

import { TelematicsWrapper } from "./containers/TelematicsWrapper/TelematicsWrapper";

TelematicsController.$inject = [
  "$element",
  "$scope",
  "$state",
  "$rootScope",
  "$transitions",
  "BACKEND_OPTIONS",
  "ENVIRONMENT",
  "Auth",
  "ResponseErrorInterceptor",
];

export default function TelematicsController(
  $element,
  $scope,
  $state,
  $rootScope,
  $transitions,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Auth,
  ResponseErrorInterceptor,
) {
  let root;
  const props = {
    ngResetFilters,
    ngGoToMainMap,
    currState: $state.current.name,
    farmId: this.farmId,
    farm: this.farm,
    initDateFrom: this.dateFrom,
    initDateTo: this.dateTo,
    initMachineFilter: this.machine,
    reducer,
    ngRedirectToMainMapWithFilters,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    apiProps: {
      farmId: this.farmId,
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
      langId: null,
    },
    cacheKey: "telematics",
  };
  UiCacheService.initialize(this.farmId);

  $scope.$on("$destroy", () => {
    root.unmount();
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  function renderWithLang() {
    const langId = Localization.getLang();
    moment.locale(langId);
    render();
  }

  function ngGoToMainMap(farmId, parcelId) {
    $state.go("farm.active.map", { farmId, parcelId });
  }

  function ngRedirectToMainMapWithFilters(
    dateFrom,
    dateTo,
    machine,
    driver,
    openInNewPage,
  ) {
    if (openInNewPage && window) {
      // Otevření v novém okně
      const url = $state.href(
        "farm.active.map",
        { dateFrom, dateTo, machine, driver },
        { absolute: true },
      );
      window.open(url, "_blank");
    } else {
      // Otevření v aktuálním okně
      window.setTimeout(() => {
        $state.go("farm.active.map", { dateFrom, dateTo, machine, driver });
      }, 100);
    }
  }

  function ngResetFilters() {
    props.initMachineFilter = null;
  }

  function render() {
    // hack for root element focus causing inputs losing focus after initial render
    $element[0].click();
    root = createRoot($element[0]);
    root.render(
      <CfApp {...props} render={(p) => <TelematicsWrapper {...p} />} />,
    );
  }
}
