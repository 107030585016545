import React from "react";

import {
  SatelliteParcelV2,
  SeasonWithCropTo,
} from "../../../../../generated/api/satellite";
import { BioMonitoringV2 } from "../../BioMonitoringV2";
import { MonitorIntervalType } from "../BiomonitoringWrapper/BiomonitoringWrapperV2";
import { IndexTypeDataProps } from "../types";
import { useGisatBiomonitoringV2 } from "../useGisatBiomonitoringV2";

type OwnProps = {
  selectedInterval?: MonitorIntervalType;
  seasonId?: string;
  setCropIntervals: (val: SeasonWithCropTo[]) => void;
  setCropIntervalsIsFetching: (val: boolean) => void;
  parcel?: SatelliteParcelV2;
};

const GisatSectionV2 = ({
  index,
  parcel,
  parcelId,
  seasonId,
  selectedInterval,
  setCropHarvestedError,
  setCropIntervals,
  setCropIntervalsIsFetching,
}: IndexTypeDataProps & OwnProps) => {
  const hookData = useGisatBiomonitoringV2({
    parcelId,
    selectedInterval,
    seasonId,
    setCropIntervals,
    setCropIntervalsIsFetching,
  });

  React.useEffect(() => {
    if (hookData && !hookData?.isFetching) {
      if (hookData.cropHarvestedError) {
        setCropHarvestedError(hookData.cropHarvestedError);
      }
    }
  }, [hookData, hookData?.isFetching, setCropHarvestedError]);

  return (
    <BioMonitoringV2
      hookData={hookData}
      index={index}
      lastUpdate={undefined}
      parcel={parcel}
      parcelId={parcelId}
    />
  );
};

export { GisatSectionV2 };
