import { queryOptions } from "@tanstack/react-query";

import { getAllFarms, getAllFarmUsers } from "../../generated/api/agroevidence";

const farmsQuery = ({ search }: { search: string }) =>
  queryOptions({
    queryKey: ["agroevidence", "get-all-farms", search],
    queryFn: ({ signal }) =>
      getAllFarms(
        {
          search,
        },
        {
          signal,
        },
      ),
  });

const farmUsersQuery = ({ farmId }: { farmId: string }) =>
  queryOptions({
    queryKey: ["agroevidence", "get-farm-users", farmId],
    queryFn: ({ signal }) => getAllFarmUsers(farmId, undefined, { signal }),
  });

export { farmsQuery, farmUsersQuery };
