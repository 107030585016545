import React from "react";

import moment from "moment";
import { createRoot } from "react-dom/client";

import reducer from "../reducers/notifications.reducer";

import CfApp from "../shared/containers/CfApp/CfApp";
import Localization from "../shared/services/Localization.service";

import NotificationsWrapper from "./containers/NotificationsWrapper/NotificationsWrapper";

NotificationsController.$inject = [
  "$element",
  "$state",
  "$scope",
  "$rootScope",
  "$transitions",
  "BACKEND_OPTIONS",
  "ENVIRONMENT",
  "Auth",
  "ResponseErrorInterceptor",
];

export default function NotificationsController(
  $element,
  $state,
  $scope,
  $rootScope,
  $transitions,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Auth,
  ResponseErrorInterceptor,
) {
  let root;
  const props = {
    farmId: this.farmId,
    farm: this.farm,
    reducer,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    currState: $state.current.name,
    apiProps: {
      farmId: this.farmId,
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
    },
    cacheKey: "notifications",
  };

  const unlistenTranslateChange = $rootScope.$on(
    "$translateChangeSuccess",
    () => {
      renderWithLang();
    },
  );

  const deregisterOnTransitionSuccess = $transitions.onBefore(
    {},
    (transition) => {
      // hack because of behaviour of ui-router vs react-router
      // we need to block transition inside sensors - when we want to switch farm, it is superseded
      const notificationsState = "farm.active.notifications";
      const transitionFrom = transition.from();
      const transitionTo = transition.to();
      const transitionParams = transition.params();
      if (
        transitionFrom.name.startsWith(notificationsState) &&
        transitionTo.name.startsWith(notificationsState) &&
        transitionParams.farmId === props.farmId
      ) {
        transition.abort();
      }
    },
  );

  $scope.$on("$destroy", () => {
    unlistenTranslateChange();
    deregisterOnTransitionSuccess();
    root.unmount();
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  function renderWithLang() {
    const langId = Localization.getLang();
    moment.locale(langId);
    render();
  }

  function render() {
    // hack for root element focus causing inputs losing focus after initial render
    $element[0].click();
    root = createRoot($element[0]);
    root.render(
      <CfApp {...props} render={(p) => <NotificationsWrapper {...p} />} />,
    );
  }
}
