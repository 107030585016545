import React from "react";

import CheckIcon from "@mui/icons-material/CheckCircle";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Stack,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import {
  RestrictionCode,
  ViolationTo,
} from "../../../../generated/api/agroevidence";

interface Props {
  onClose: () => void;
  showDialog: boolean;
  filteredViolations: ViolationTo[][];
}
const CropRotationResultDialog = ({
  filteredViolations,
  onClose,
  showDialog,
}: Props) => {
  const classes = useStyles();
  const { farmId } = useParams<{ farmId: string }>();

  const getGroupedUniqueData = (violations: ViolationTo[]) =>
    violations.length > 0 ? _.groupBy(violations, "code") : undefined;

  return (
    <Dialog
      data-test="report-crop-rotation-dialog"
      maxWidth="md"
      onClose={onClose}
      open={showDialog}
    >
      <DialogTitle>
        <FormattedMessage id="Reports.cropRotation.heading" />
      </DialogTitle>
      <DialogContent>
        {filteredViolations.every(_.isEmpty) && (
          <div className={classes.successMsg}>
            <CheckIcon color="primary" />
            <FormattedMessage id="Reports.cropRotation.successMsg" />
          </div>
        )}
        {filteredViolations.map((violations) => {
          const groupedUniqueData = getGroupedUniqueData(violations);

          return (
            <>
              {groupedUniqueData &&
                Object.entries(groupedUniqueData).map(
                  ([code, items], codeIndex) => (
                    <Grid key={codeIndex}>
                      <Grid className={classes.restrictionCode}>
                        {code ===
                          RestrictionCode.SlopeWaterSowingRestriction && (
                          <FormattedMessage id="Reports.cropRotation.codeRestrictionViolated.SlopeWaterSowingRestriction" />
                        )}
                        {code !==
                          RestrictionCode.SlopeWaterSowingRestriction && (
                          <FormattedMessage
                            id="Reports.cropRotation.codeRestrictionViolated"
                            values={{
                              code,
                            }}
                          />
                        )}
                      </Grid>
                      <Grid className={classes.content}>
                        <div
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: items[0].text }}
                        />
                      </Grid>
                      <Grid className={classes.itemsContainer}>
                        {items.map((item, index) => (
                          <Grid
                            className={classes.content}
                            container
                            data-test="restrictions-content"
                            key={`${code}-${index}`}
                          >
                            <Grid item xs={12}>
                              <Link
                                href={`/farm/${farmId}/parcels/${item.parcelId}/overview`}
                                target="_blank"
                                underline="hover"
                              >
                                {item.parcel?.name}
                              </Link>
                              <Stack>
                                {item.parcel?.violatedCrops?.map((crop) => (
                                  <Stack direction="row" key={crop.cropName}>
                                    <span>-&nbsp;{crop.cropName}&nbsp;(</span>
                                    <FormattedDate
                                      format="d.m.YYYY"
                                      value={crop.cropStartDate}
                                    />
                                    <span>&nbsp;-&nbsp;</span>
                                    <FormattedDate
                                      format="d.m.YYYY"
                                      value={crop.cropEndDate}
                                    />
                                    <span>)</span>
                                  </Stack>
                                ))}
                              </Stack>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      {codeIndex <
                        Object.keys(groupedUniqueData).length - 1 && (
                        <Divider
                          className={classes.content}
                          variant="fullWidth"
                        />
                      )}
                    </Grid>
                  ),
                )}
            </>
          );
        })}
      </DialogContent>
    </Dialog>
  );
};

export { CropRotationResultDialog };

const useStyles = makeStyles((theme: Theme) => ({
  itemsContainer: {
    padding: "5px 15px",
  },
  restrictionCode: {
    margin: "20px 0px",
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.secondary.dark,
  },
  content: {
    marginBottom: 20,
  },
  successMsg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0px",
    fontSize: 16,
    fontWeight: 500,
  },
}));
