import React from "react";

import moment from "moment";
import { createRoot } from "react-dom/client";

import reducer from "../../reducers/actions.reducer";

import CfApp from "../../shared/containers/CfApp/CfApp";
import Localization from "../../shared/services/Localization.service";

import ActionsWrapper from "./shared/containers/ActionsWrapper/ActionsWrapper";

ActionsController.$inject = [
  "$element",
  "$scope",
  "$state",
  "$rootScope",
  "BACKEND_OPTIONS",
  "ENVIRONMENT",
  "Auth",
  "ResponseErrorInterceptor",
];

export default function ActionsController(
  $element,
  $scope,
  $state,
  $rootScope,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Auth,
  ResponseErrorInterceptor,
) {
  let root;
  const props = {
    farmId: this.farmId,
    initParcelIds: this.parcelIds,
    initZoneIds: this.zoneIds,
    currState: $state.current.name,
    ngGoToActions,
    ngGoToAction,
    ngGoToEph,
    ngGoToSowing,
    ngGoToVrf,
    ngGoToVrs,
    ngGoToOtherNew,
    ngGoToHarvest,
    ngGoToMowing,
    reducer,
    farm: this.farm,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    apiProps: {
      farmId: this.farmId,
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
    },
    cacheKey: "actions",
  };

  const unlistenTranslateChange = $rootScope.$on(
    "$translateChangeSuccess",
    () => {
      renderWithLang();
    },
  );

  $scope.$on("$destroy", () => {
    unlistenTranslateChange();
    root.unmount();
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  function renderWithLang() {
    const langId = Localization.getLang();
    moment.locale(langId);
    render();
  }

  function ngGoToSowing() {
    return $state.go("farm.active.actions.sowing");
  }

  function ngGoToOtherNew() {
    return $state.go("farm.active.actions.otherNew");
  }

  function ngGoToHarvest() {
    return $state.go("farm.active.actions.harvest");
  }

  function ngGoToMowing() {
    return $state.go("farm.active.actions.mowing");
  }

  function ngGoToEph() {
    return $state.go("farm.active.actions.eph");
  }

  function ngGoToVrf() {
    return $state.go("farm.active.actions.vrf");
  }

  function ngGoToVrs() {
    return $state.go("farm.active.actions.vrs");
  }

  function ngGoToActions() {
    return $state.go("farm.active.actions");
  }

  function ngGoToAction(actionId, route) {
    return $state.go(`farm.active.${route}`, { actionId });
  }

  function render() {
    // hack for root element focus causing inputs losing focus after initial render
    $element[0].click();
    root = createRoot($element[0]);
    root.render(<CfApp {...props} render={(p) => <ActionsWrapper {...p} />} />);
  }
}
