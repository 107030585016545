import React, { Component, Fragment } from "react";

import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

import { HelpIcon } from "../../../../shared/icons/navbar/HelpIcon";
import HelpMenu from "../HelpMenu/HelpMenu";
import MainMenu from "../MainMenu/MainMenu";
import UserAvatar from "../UserAvatar/UserAvatar";
import UserMenu from "../UserMenu/UserMenu";

const styles = (theme) => ({
  drawerPaper: {
    width: 275,
  },
  mainMenu: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    height: "100%",
    overflowY: "auto",
  },
  userHelpMenuContent: {
    marginTop: 8,
  },
  menuButton: {
    marginRight: 10,
    marginLeft: 10,
    zIndex: 9999,
  },
  toolbarMenuItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    color: "rgba(255, 255, 255, 0.7)",
    width: 50,
    "&:hover": {
      color: "#FFFFFF",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    height: 56,
    alignItems: "center",
    marginRight: 10,
  },
});

class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      type: "main",
    };
  }

  setMenuType = (menuType) => {
    this.setState({
      type: menuType,
    });
  };

  toggleDrawer = (open) => {
    this.setState({
      open,
    });
    if (!open) {
      this.setState({
        type: "main",
      });
    }
  };

  render() {
    const { classes, ngProps } = this.props;
    const { currState, farm, logout, user } = ngProps;
    const { type, username } = this.state;
    return (
      <Fragment>
        <div
          aria-label="Open menu"
          className={`${classes.toolbarMenuItem} ${classes.menuButton}`}
          id="mobile-menu-open"
          onClick={() => this.toggleDrawer(true)}
          role="button"
          tabIndex="0"
        >
          <MenuIcon style={{ width: 28, height: 28 }} />
        </div>
        <SwipeableDrawer
          anchor="right"
          disableSwipeToOpen={true}
          onClose={() => this.toggleDrawer(false)}
          onOpen={() => this.toggleDrawer(true)}
          open={this.state.open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {type === "main" && (
            <div className={classes.mainMenu}>
              <div className={classes.toolbar}>
                <div
                  aria-label="Open help menu"
                  className={classes.toolbarMenuItem}
                  id="mobile-help-menu"
                  onClick={() => this.setMenuType("help")}
                  role="button"
                  tabIndex="0"
                >
                  <HelpIcon style={{ width: 32, height: 32 }} />
                </div>
                <div
                  aria-label="Open user menu"
                  className={classes.toolbarMenuItem}
                  id="mobile-user-menu"
                  onClick={() => this.setMenuType("user")}
                  role="button"
                  tabIndex="0"
                >
                  <UserAvatar mobile={true} user={user} />
                </div>
              </div>
              <MainMenu
                currState={currState}
                farmId={farm.id}
                logout={logout}
                mobile={true}
                onMenuItemClick={() => this.toggleDrawer(false)}
              />
            </div>
          )}
          {type !== "main" && (
            <div>
              <div className={classes.toolbar}>
                <IconButton
                  id="mobile-secondary-menu-close"
                  onClick={() => this.setMenuType("main")}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className={classes.userHelpMenuContent}>
                {type === "help" && (
                  <HelpMenu
                    mobile={true}
                    onMenuItemClick={() => this.toggleDrawer(false)}
                  />
                )}
                {type === "user" && (
                  <div>
                    <UserMenu
                      mobile={true}
                      ngProps={ngProps}
                      onImpersonationToggle={this.props.onImpersonationToggle}
                      onMenuItemClick={() => this.toggleDrawer(false)}
                      username={username}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </SwipeableDrawer>
      </Fragment>
    );
  }
}

MobileMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  ngProps: PropTypes.object.isRequired,
  onImpersonationToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(MobileMenu);
