import React from "react";

import moment from "moment";
import { createRoot } from "react-dom/client";

import reducer from "../../reducers/reset.reducer";

import CfApp from "../../shared/containers/CfApp/CfApp";
import Localization from "../../shared/services/Localization.service";

import ResetWrapper from "./containers/ResetWrapper/ResetWrapper";

ResetController.$inject = [
  "$element",
  "$state",
  "$stateParams",
  "$scope",
  "$rootScope",
  "BACKEND_OPTIONS",
  "ENVIRONMENT",
];

export default function ResetController(
  $element,
  $state,
  $stateParams,
  $scope,
  $rootScope,
  BACKEND_OPTIONS,
  ENVIRONMENT,
) {
  let root;
  const props = {
    getResetKey,
    gwUrl: BACKEND_OPTIONS.gatewayUrl,
    ngRedirectToLogin,
    reducer,
    currState: $state.current.name,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    apiProps: {
      beOpts: BACKEND_OPTIONS,
      displayErrorModal: false,
    },
  };

  const unlistenTranslateChange = $rootScope.$on(
    "$translateChangeSuccess",
    () => {
      renderWithLang();
    },
  );

  $scope.$on("$destroy", () => {
    unlistenTranslateChange();
    root.unmount();
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  function renderWithLang() {
    const langId = Localization.getLang();
    moment.locale(langId);
    render();
  }

  function getResetKey() {
    return $stateParams.key;
  }

  function ngRedirectToLogin() {
    $state.go("login");
  }

  function render() {
    $element[0].click();
    root = createRoot($element[0]);
    root.render(<CfApp {...props} render={(p) => <ResetWrapper {...p} />} />);
  }
}
