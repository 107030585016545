import React, { useMemo } from "react";

import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { IconButton, Collapse, TableCell, Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import moment from "moment";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import {
  getDriverAggregationsCount,
  isFetchingDriverAggregations,
} from "../../../../../shared/api/telematics/aggregations/aggregations.selectors";
import {
  getTelematicsAggregationsBulkEditMode,
  getTelematicsAggregationsOpenedDriverRows,
  getTelematicsAggregationsSelected,
  getTelematicsAggregationsSelectedRides,
} from "../../../../selectors/telematicsAggregations.selectors";

import { setTableSubrowSelected } from "../../../../../shared/actions/table.actions";
import { setOpenedDriverRows } from "../../../../actions/telematicsAggregations.actions";

import { TELEMATICS } from "../../../../../core/map/constants/contexts.constants";

import { NAMESPACE as namespace } from "../../../../reducer/telematicsAggregations.reducer";

import showOnMapIcon from "../../../../../assets/img/icons/telematics/showOnMap.svg";
import {
  Affiliation,
  LogbookAggregatedTo,
  State,
  Type,
} from "../../../../../generated/api/telematics";
import { MAP_CONTEXT_LS_KEY } from "../../../../../saga/mainMap.saga";
import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import TimeIntervalsBar from "../../../../../shared/components/misc/TimeIntervalsBar/TimeIntervalsBar";
import CfTableCell from "../../../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableCheckbox from "../../../../../shared/containers/CfTableCheckbox/CfTableCheckbox";
import LocalStorage from "../../../../../shared/services/LocalStorage.service";
import TelematicsForeignWarning, {
  TelematicsForeignWarningType,
} from "../../../../components/TelematicsForeignWarning/TelematicsForeignWarning";
import { TelematicsNgProps } from "../../../../containers/Telematics/Telematics";
import { useLogLastVisitedTab } from "../../../../containers/TelematicsTabs/useTelematicsLogger";
import { duplicateTabHandler, getDuration } from "../../../../helpers";

import AggregatedRidesList from "./AggregatedRidesList";
import ApprovalButton from "./ApprovalButton";
import { getIsDisabled } from "./RideRow";

interface OwnProps {
  columnsLength: number;
  data: LogbookAggregatedTo;
  dateFilter: {
    dateFrom: string;
    dateTo: string;
  };
  logbookFilterHandler: (
    dateFrom: string,
    dateTo: string,
    driver: Record<"code" | "name", string>,
  ) => void;
  rowId: number;
}
type Props = OwnProps & TelematicsNgProps;

const DriverRow = ({
  columnsLength,
  data,
  dateFilter,
  logbookFilterHandler,
  ngRedirectToMainMapWithFilters,
  rowId,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { farmId } = useParams<{ farmId: string }>();
  const intl = useIntl();
  const logLastVisitedTab = useLogLastVisitedTab();
  const openedRows = useSelector(getTelematicsAggregationsOpenedDriverRows);
  const selected = useSelector(getTelematicsAggregationsSelected);
  const selectedRides = useSelector(getTelematicsAggregationsSelectedRides);
  const bulkEditMode = useSelector(getTelematicsAggregationsBulkEditMode);
  const count = useSelector(getDriverAggregationsCount);
  const isFetching = useSelector(isFetchingDriverAggregations);
  const opened = openedRows?.includes(rowId) ?? false;

  const toggleOpened = () => {
    if (opened) {
      dispatch(setOpenedDriverRows(openedRows.filter((id) => id !== rowId)));
    } else {
      dispatch(setOpenedDriverRows([...openedRows, rowId]));
    }
  };

  const timeIntervals = useMemo(
    () =>
      data.times
        .filter((i) => i.type !== Type.HANDWORK)
        .map(({ dateFrom, dateTo }) => `${dateFrom}/${dateTo}`),
    [data.times],
  );
  const handworkIntervals = useMemo(
    () =>
      data.times
        .filter((i) => i.type === Type.HANDWORK)
        .map(({ dateFrom, dateTo }) => `${dateFrom}/${dateTo}`),
    [data.times],
  );

  const ridesStates = useMemo(
    () => new Set(data.drives.map(({ state }) => state)),
    [data.drives],
  );
  const ridesValidations = useMemo(
    () =>
      new Set(
        data.drives
          .filter((drive) => drive.state !== "DEFERRED")
          .map(({ validation }) => validation.isComplete),
      ),
    [data.drives],
  );

  const ridesIds = useMemo(
    () =>
      data.drives
        .filter((drive) => drive.state === State.NOT_APPROVED)
        .map(({ id }) => id),
    [data.drives],
  );

  let driverState: State = State.THIRD_PARTY_ACKNOWLEDGED;
  if (ridesStates.has(State.NOT_APPROVED)) {
    driverState = State.NOT_APPROVED;
  } else if (ridesStates.has(State.APPROVED)) {
    driverState = State.APPROVED;
  } else if (ridesStates.size === 1 && ridesStates.has(State.DEFERRED)) {
    driverState = State.DEFERRED;
  }
  const areAllDrivesValid = !ridesValidations.has(false);
  const driverFilterFallback = {
    code: "nodriver",
    name: intl.formatMessage({ id: "TelematicsList.filter.withoutDriver" }),
  };

  const handleShowOnMapClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const driver = data.driver ?? driverFilterFallback;
    const openInNewPage = duplicateTabHandler({ driver, ...dateFilter })(e);
    LocalStorage.saveToLocalStorage(TELEMATICS, MAP_CONTEXT_LS_KEY);
    ngRedirectToMainMapWithFilters(
      dateFilter.dateFrom,
      dateFilter.dateTo,
      undefined,
      [driver],
      openInNewPage,
    );
  };

  const handleFilterInRidesClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const driver = {
      name: data.driver?.name ?? driverFilterFallback.name,
      code: data.driver?.code ?? driverFilterFallback.code,
    };
    const openInNewPage = duplicateTabHandler({ driver, ...dateFilter })(e);
    const urlToRedirect = `/farm/${farmId}/telematics/logbook`;

    if (openInNewPage) {
      logLastVisitedTab("logbook");
      window.open(urlToRedirect, "_blank");
    } else {
      logbookFilterHandler(dateFilter.dateFrom, dateFilter.dateTo, driver);
      history.push(urlToRedirect);
    }
  };

  return (
    <>
      <TableRow data-test="wrapped-row" onClick={toggleOpened}>
        {bulkEditMode && (
          <CfTableCheckbox
            disabled={!data.drives.some((d) => !getIsDisabled(d))}
            id={data.drives[0].id}
            name="id"
            namespace={namespace}
            selected={selected}
            onCheck={() => {
              const toAdd = data.drives
                .filter((d) => !getIsDisabled(d))
                .map((d) => d.id);
              const uniqueToAdd = toAdd.filter(
                (val) => !selectedRides.includes(val),
              );
              const res = [...selectedRides, ...uniqueToAdd];
              dispatch(setTableSubrowSelected(res, namespace));
            }}
            onUncheck={() => {
              const toRemove = data.drives.map((d) => d.id);
              const res = selectedRides.filter(
                (val) => !toRemove.includes(val),
              );
              dispatch(setTableSubrowSelected(res, namespace));
            }}
          />
        )}
        <CfTableCell name="duration">
          <div
            className={classnames(
              classes.cellMultipleRowsContainer,
              classes.withLeftOffset,
            )}
          >
            <div>
              <FormattedDate value={data.dateFrom} />
            </div>
            <div className={classes.greyText}>{getDuration(data.duration)}</div>
          </div>
        </CfTableCell>
        <CfTableCell name="driver.name">
          <div className={classes.cellMultipleRowsContainer}>
            <div>{data.driver?.name ?? "-"}</div>
            {data.driver?.affiliation === Affiliation.EXTERNAL && (
              <TelematicsForeignWarning
                text={data.driver?.companyName}
                type={TelematicsForeignWarningType.Driver}
              />
            )}
            {data.driver?.affiliation !== Affiliation.EXTERNAL && (
              <div className={classes.greyText}>{data.driver?.code ?? "-"}</div>
            )}
          </div>
        </CfTableCell>
        <CfTableCell name="cultivated">
          <div>
            {data.cultivated ? (
              <span>
                <CfFormattedNumber decimalDigits={2} value={data.cultivated} />
                {" ha"}
              </span>
            ) : (
              "-"
            )}
          </div>
        </CfTableCell>
        <CfTableCell name="distance">
          <span>
            <CfFormattedNumber decimalDigits={2} value={data.distance} />{" "}
            {data.distance >= 0 ? " km" : ""}
          </span>
        </CfTableCell>
        <CfTableCell name="time">
          <div className={classes.lastCelContainer}>
            <div className={classes.timeGraph}>
              <div className={classes.times}>
                {moment(data.dateFrom).format("HH:mm")}
                {" - "} {moment(data.dateTo).format("HH:mm")}
              </div>
              <TimeIntervalsBar
                datetimeEnd={moment(data.dateFrom).endOf("day").toISOString()}
                handworkIntervals={handworkIntervals}
                intervals={timeIntervals}
                datetimeStart={moment(data.dateFrom)
                  .startOf("day")
                  .toISOString()}
              />
            </div>
            <div className={classes.icons}>
              {!bulkEditMode && (
                <>
                  <Tooltip
                    title={
                      <FormattedMessage id="TelematicsAggregations.list.showOnMap" />
                    }
                  >
                    <IconButton
                      aria-label="show on map"
                      className={classes.iconsBtn}
                      onClick={handleShowOnMapClick}
                      onMouseDown={handleShowOnMapClick}
                    >
                      <img alt="show on map" src={showOnMapIcon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      <FormattedMessage id="TelematicsAggregations.list.filterInRides" />
                    }
                  >
                    <IconButton
                      aria-label="filter in rides"
                      className={classes.iconsBtn}
                      onClick={handleFilterInRidesClick}
                      onMouseDown={handleFilterInRidesClick}
                    >
                      <FilterListIcon />
                    </IconButton>
                  </Tooltip>
                  <ApprovalButton
                    complete={areAllDrivesValid}
                    driveIds={ridesIds.map((ride) => parseInt(ride, 10))}
                    iconTooltipId="TelematicsAggregations.incompleteRide.driver.short"
                    state={driverState}
                    tooltipId="TelematicsAggregations.incompleteRide.driver.long"
                  />
                </>
              )}
              <IconButton
                aria-label="Toggle view"
                className={classnames(classes.collapseIcon, classes.iconsBtn)}
                size="large"
              >
                {opened ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </div>
          </div>
        </CfTableCell>
      </TableRow>
      <TableRow className={classes.collapsedRow}>
        <TableCell className={classes.collapsedCell} colSpan={columnsLength}>
          <Collapse in={opened}>
            {data.drives && (
              <AggregatedRidesList
                data={data.drives}
                isInit={!isFetching && count > 0}
                mainRowData={data}
                opened={opened}
              />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  collapsedRow: {
    height: 0,
  },
  collapsedCell: {
    padding: 0,
    borderBottom: "none",
  },
  cellMultipleRowsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  timeGraph: {
    display: "flex",
    alignItems: "center",
    width: "48vw",
  },
  times: {
    marginRight: theme.spacing(1),
    whiteSpace: "nowrap",
  },
  lastCelContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  greyText: {
    color: theme.palette.grey[400],
  },
  withLeftOffset: {
    marginLeft: 16,
  },
  icons: {
    display: "flex",
    width: 300,
  },
  iconsBtn: {
    height: "fit-content",
    alignSelf: "center",
  },
  collapseIcon: {
    marginLeft: "auto",
  },
}));

export { DriverRow };
