import React, { useState } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getMonitoringCropIntervalsIsFetching,
  getMonitoringDataError,
} from "../../../../../shared/api/sentinel/monitoring/monitoring.selectors";
import { getPrecisionParcel } from "../../../../../shared/api/sentinel/precision/precision.selectors";
import {
  getCropIntervals,
  getIndex,
  getMonitoringStatus,
  getSelectedIntervalId,
} from "../../../selectors/monitoring.selectors";

import {
  setMonitoringIndex,
  setMonitoringInterval,
} from "../../../actions/monitoring.actions";

import { LANGUAGE_ID } from "../../../../../shared/lang/lang.constants";

import CfErrorPage from "../../../../../shared/components/common/CfErrorPage/CfErrorPage";
import useWidth from "../../../../../shared/hooks/useWidth";
import BioMonitoringStatusWrapper from "../../../components/BioMonitoring/BioMonitoringStatusWrapper";
import { OverallImagesError } from "../../../services/BioMonitoringImageError.services";
import { MonitoringCropIntervals } from "../../BioMonitoring";
import { CropHarvestedError } from "../CropHarvestedError";
import { GisatSection } from "../GisatSection/GisatSection";
import { HeaderBar } from "../HeaderBar";
import { PlanetSection } from "../PlanetSection/PlanetSection";
import { useCommonBioMonitoring } from "../useCommonBioMonitoring";

import { isPlanetIndexType } from "./helpers";

type Props = {
  langId: LANGUAGE_ID;
  ngRedirectToSowing: (parcelId: string[]) => void;
  parcelId: string;
};

const BiomonitoringWrapper = ({
  langId,
  ngRedirectToSowing,
  parcelId,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const width = useWidth();
  const index = useSelector(getIndex);
  const error = useSelector(getMonitoringDataError);
  const monitoringStatus = useSelector(getMonitoringStatus);
  const parcel = useSelector(getPrecisionParcel);
  const cropIntervals = useSelector(getCropIntervals);

  const cropIntervalsIsFetching = useSelector(
    getMonitoringCropIntervalsIsFetching,
  );
  const selectedIntervalId = useSelector(getSelectedIntervalId);

  const { activeIndicesList, isInit } = useCommonBioMonitoring({
    cropIntervals,
    cropIntervalsIsFetching,
    selectedIntervalId,
    setSelectedInterval: (dateFrom, dateTo, id) =>
      dispatch(setMonitoringInterval(dateFrom, dateTo ?? "", id)),
    activeBiomonitoringIndices: parcel?.activeBiomonitoringIndices ?? [],
  });

  const [cropHarvestedError, setCropHarvestedError] = useState<
    OverallImagesError | null | undefined
  >(undefined);

  const isPlanet = isPlanetIndexType(index);

  return (
    <CfErrorPage error={error}>
      <BioMonitoringStatusWrapper
        langId={langId}
        monitoringStatus={monitoringStatus}
        width={width}
      >
        <>
          {cropHarvestedError && (
            <CropHarvestedError
              cropHarvestedErrorIcon={cropHarvestedError.icon}
              cropHarvestedErrorId={cropHarvestedError.id}
              ngRedirectToSowing={ngRedirectToSowing}
              parcelId={parcelId}
            />
          )}
          <HeaderBar
            cropIntervals={cropIntervals}
            index={index}
            items={activeIndicesList}
            selectedIntervalId={selectedIntervalId}
            setMonitoringIndex={(i) => dispatch(setMonitoringIndex(i))}
            getItemViewValue={(item: MonitoringCropIntervals) => (
              <span>
                <span className={classes.dateInterval}>
                  <FormattedDate
                    month="short"
                    value={item.from}
                    year="numeric"
                  />
                  {" \u2013 "}
                  {item.to ? (
                    <FormattedDate
                      month="short"
                      value={item.to}
                      year="numeric"
                    />
                  ) : (
                    <FormattedMessage id="common.now" />
                  )}
                </span>{" "}
                <span className={classes.cropName}>
                  {item.crop?.name ?? <FormattedMessage id="common.noCrop" />}
                </span>
              </span>
            )}
            setMonitoringInterval={(from, to, selectedId) => {
              dispatch(setMonitoringInterval(from, to, selectedId));
            }}
          />
          {isInit && (
            <>
              {!isPlanet && (
                <GisatSection
                  index={index}
                  parcelId={parcelId}
                  setCropHarvestedError={setCropHarvestedError}
                />
              )}
              {isPlanet && (
                <PlanetSection
                  index={index}
                  parcelId={parcelId}
                  setCropHarvestedError={setCropHarvestedError}
                />
              )}
            </>
          )}
        </>
      </BioMonitoringStatusWrapper>
    </CfErrorPage>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  cropName: {
    color: theme.palette.grey[500],
    display: "inline-block",
  },
  dateInterval: {
    textTransform: "lowercase",
    marginRight: 6,
  },
}));

export { BiomonitoringWrapper };
