import React, { useMemo } from "react";

import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { IconButton, Collapse, TableCell, Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import moment from "moment";
import { FormattedDate, FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getMachineAggregationsCount,
  isFetchingMachineAggregations,
} from "../../../../../shared/api/telematics/machines/machines.selectors";
import { getTelematicsMachinesOpenedRows } from "../../../../selectors/telematicsMachines.selectors";

import { setOpenedMachineRows } from "../../../../actions/telematicsMachines.actions";

import { TELEMATICS } from "../../../../../core/map/constants/contexts.constants";

import showOnMapIcon from "../../../../../assets/img/icons/telematics/showOnMap.svg";
import {
  Affiliation,
  LogbookAggregatedMachineTo,
  MachineCategoryCode,
  MachineTo,
} from "../../../../../generated/api/telematics";
import { MAP_CONTEXT_LS_KEY } from "../../../../../saga/mainMap.saga";
import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import TimeIntervalsBar from "../../../../../shared/components/misc/TimeIntervalsBar/TimeIntervalsBar";
import CfTableCell from "../../../../../shared/components/tables/CfTableCell/CfTableCell";
import LocalStorage from "../../../../../shared/services/LocalStorage.service";
import { Thunk } from "../../../../../types";
import TelematicsForeignWarning, {
  TelematicsForeignWarningType,
} from "../../../../components/TelematicsForeignWarning/TelematicsForeignWarning";
import { TelematicsNgProps } from "../../../../containers/Telematics/Telematics";
import { useLogLastVisitedTab } from "../../../../containers/TelematicsTabs/useTelematicsLogger";
import { duplicateTabHandler, getDuration } from "../../../../helpers";

import { MachineRidesList } from "./MachineRidesList";

import { TelematicsState } from "../../../../../reducers/telematics.reducer.types";
import { TelematicsListDateFilter } from "../../../../telematics.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  data: LogbookAggregatedMachineTo;
  dateFilter: TelematicsListDateFilter;
  logbookFilterHandler: (
    dateFrom: string,
    dateTo: string,
    machine: Record<"name" | "gpsUnit", string>,
  ) => void;
  rowId: number;
};
type Props = ReduxProps & OwnProps & TelematicsNgProps;

const MachineRow = ({
  count,
  data,
  dateFilter,
  isFetching,
  logbookFilterHandler,
  ngRedirectToMainMapWithFilters,
  openedRows,
  rowId,
  setOpenedRows,
}: Props) => {
  const opened = openedRows?.includes(rowId) ?? false;
  const toggleOpened = () => {
    if (opened) {
      setOpenedRows(openedRows.filter((id) => id !== rowId));
    } else {
      setOpenedRows([...openedRows, rowId]);
    }
  };
  const classes = useStyles();
  const history = useHistory();
  const { farmId } = useParams<{ farmId: string }>();
  const logLastVisitedTab = useLogLastVisitedTab();

  const timeIntervals = useMemo(
    () => data.times.map(({ dateFrom, dateTo }) => `${dateFrom}/${dateTo}`),
    [data.times],
  );

  const handleShowOnMapClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const machine: MachineTo = {
      validFrom: "", // required prop
      category: MachineCategoryCode.AJE, // required prop
      ...data.machine,
    };
    const openInNewPage = duplicateTabHandler({ machine, ...dateFilter })(e);
    LocalStorage.saveToLocalStorage(TELEMATICS, MAP_CONTEXT_LS_KEY);
    ngRedirectToMainMapWithFilters(
      dateFilter.dateFrom,
      dateFilter.dateTo,
      [machine],
      undefined,
      openInNewPage,
    );
  };

  const handleFilterInRidesClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const machine = {
      name: data.machine.name ?? "",
      gpsUnit: data.machine.gpsUnit,
    };
    const openInNewPage = duplicateTabHandler({ machine, ...dateFilter })(e);
    const urlToRedirect = `/farm/${farmId}/telematics/logbook`;

    if (openInNewPage) {
      logLastVisitedTab("logbook");
      window.open(urlToRedirect, "_blank");
    } else {
      logbookFilterHandler(dateFilter.dateFrom, dateFilter.dateTo, machine);
      history.push(urlToRedirect);
    }
  };

  return (
    <>
      <TableRow data-test="wrapped-row" onClick={toggleOpened}>
        <CfTableCell name="duration">
          <div
            className={classnames(
              classes.cellMultipleRowsContainer,
              classes.withLeftOffset,
            )}
          >
            <div>
              <FormattedDate value={data.dateFrom} />
            </div>
            <div className={classes.greyText}>{getDuration(data.duration)}</div>
          </div>
        </CfTableCell>
        <CfTableCell name="machine.name">
          <div className={classes.cellMultipleRowsContainer}>
            {!(!data.machine?.name && !data.machine?.licencePlate) && (
              <>
                <div>{data.machine?.name ?? "-"}</div>
                <div className={classes.greyText}>
                  {data.machine?.licencePlate ?? "-"}
                </div>
              </>
            )}
            {!data.machine?.name && !data.machine?.licencePlate && (
              <div>{data.machine?.gpsUnit ?? "-"}</div>
            )}
            {data.machineAffiliation === Affiliation.EXTERNAL ? (
              <TelematicsForeignWarning
                text={data.machineCompanyName}
                type={TelematicsForeignWarningType.Machine}
              />
            ) : null}
          </div>
        </CfTableCell>
        <CfTableCell name="cultivated">
          <div>
            {data.cultivated ? (
              <span>
                <CfFormattedNumber decimalDigits={2} value={data.cultivated} />
                {" ha"}
              </span>
            ) : (
              "-"
            )}
          </div>
        </CfTableCell>
        <CfTableCell name="distance">
          <span>
            <CfFormattedNumber decimalDigits={2} value={data.distance} />{" "}
            {data.distance >= 0 ? " km" : ""}
          </span>
        </CfTableCell>
        <CfTableCell name="time">
          <div className={classes.lastCelContainer}>
            <div className={classes.timeGraph}>
              <div className={classes.times}>
                {moment(data.dateFrom).format("HH:mm")}
                {" - "} {moment(data.dateTo).format("HH:mm")}
              </div>
              <TimeIntervalsBar
                datetimeEnd={moment(data.dateFrom).endOf("day").toISOString()}
                intervals={timeIntervals}
                datetimeStart={moment(data.dateFrom)
                  .startOf("day")
                  .toISOString()}
              />
            </div>
            <div className={classes.icons}>
              <Tooltip
                title={
                  <FormattedMessage id="TelematicsAggregations.list.showOnMap" />
                }
              >
                <IconButton
                  aria-label="show on map"
                  className={classes.iconsBtn}
                  onClick={handleShowOnMapClick}
                  onMouseDown={handleShowOnMapClick}
                >
                  <img alt="show on map" src={showOnMapIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  <FormattedMessage id="TelematicsAggregations.list.filterInRides" />
                }
              >
                <IconButton
                  aria-label="filter in rides"
                  className={classes.iconsBtn}
                  onClick={handleFilterInRidesClick}
                  onMouseDown={handleFilterInRidesClick}
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>

              <IconButton
                aria-label="Toggle view"
                className={classes.iconsBtn}
                size="large"
              >
                {opened ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </div>
          </div>
        </CfTableCell>
      </TableRow>
      <TableRow className={classes.collapsedRow}>
        <TableCell className={classes.collapsedCell} colSpan={5}>
          <Collapse in={opened}>
            {data.drives && (
              <MachineRidesList
                data={data.drives}
                isInit={!isFetching && count > 0}
                opened={opened}
              />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  openedRows: getTelematicsMachinesOpenedRows(state),
  count: getMachineAggregationsCount(state),
  isFetching: isFetchingMachineAggregations(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      setOpenedRows: setOpenedMachineRows,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(MachineRow);

const useStyles = makeStyles((theme: Theme) => ({
  collapsedRow: {
    height: 0,
  },
  collapsedCell: {
    padding: 0,
    borderBottom: "none",
  },
  cellMultipleRowsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  timeGraph: {
    display: "flex",
    alignItems: "center",
    width: "48vw",
  },
  times: {
    marginRight: 10,
    whiteSpace: "nowrap",
  },
  lastCelContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  greyText: {
    color: theme.palette.grey[400],
  },
  withLeftOffset: {
    marginLeft: 16,
  },
  icons: {
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      flex: 1,
      paddingLeft: 16,
    },
    "& >:last-child": {
      marginLeft: "auto",
    },
  },
  iconsBtn: {
    height: "fit-content",
    alignSelf: "center",
  },
}));
