import React from "react";

import { LANG_KEYS, LANGUAGE_ID } from "../../shared/lang/lang.constants";

import BaseContainer from "../../shared/containers/BaseContainer/BaseContainer";
import translationsCZ from "../shared/lang/locale-cs-CZ.json";
import translationsEL from "../shared/lang/locale-el-GR.json";
import translationsEN from "../shared/lang/locale-en-US.json";
import translationsES from "../shared/lang/locale-es-ES.json";
import translationsHU from "../shared/lang/locale-hu-HU.json";
import translationsPL from "../shared/lang/locale-pl-PL.json";
import translationsRO from "../shared/lang/locale-ro-RO.json";

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.EL]: translationsEL,
  [LANG_KEYS.RO]: translationsRO,
  [LANG_KEYS.HU]: translationsHU,
  [LANG_KEYS.PL]: translationsPL,
};

const ImpersonationWrapper = ({
  children,
  langId,
}: React.PropsWithChildren<{ langId: LANGUAGE_ID }>) => (
  <BaseContainer langId={langId} translations={translations}>
    {children}
  </BaseContainer>
);

export default ImpersonationWrapper;
