import React from "react";

import moment from "moment";
import { createRoot } from "react-dom/client";

import reducer from "../../reducers/reports.reducer";

import CfApp from "../../shared/containers/CfApp/CfApp";
import Localization from "../../shared/services/Localization.service";

import ReportsWrapper from "./containers/ReportsWrapper/ReportsWrapper";

ReportsController.$inject = [
  "$state",
  "$element",
  "$scope",
  "$rootScope",
  "BACKEND_OPTIONS",
  "ENVIRONMENT",
  "Auth",
  "ResponseErrorInterceptor",
];

export default function ReportsController(
  $state,
  $element,
  $scope,
  $rootScope,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Auth,
  ResponseErrorInterceptor,
) {
  let root;
  const props = {
    farm: this.farm,
    currState: $state.current.name,
    reducer,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    apiProps: {
      farmId: this.farm.id,
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
    },
  };

  const unlistenTranslateChange = $rootScope.$on(
    "$translateChangeSuccess",
    () => {
      renderWithLang();
    },
  );

  $scope.$on("$destroy", () => {
    unlistenTranslateChange();
    root.unmount();
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  const renderWithLang = () => {
    const langId = Localization.getLang();
    moment.locale(langId);
    render();
  };

  const render = () => {
    $element[0].click();
    root = createRoot($element[0]);
    root.render(<CfApp {...props} render={(p) => <ReportsWrapper {...p} />} />);
  };
}
