import axios from "axios";
import head from "lodash/head";
import trim from "lodash/trim";
import localStorageService from "local-storage";
import { JwtService } from "../../../shared/services/Jwt.service";
import UiCache from "../../../shared/services/UiCache.service";
import Intercom from "../../../shared/services/Intercom.service";
import Hotjar from "../../../shared/services/Hotjar.service";
import LocalStorage from "../../../shared/services/LocalStorage.service";
import GoogleAnalytics from "../../../shared/services/GoogleAnalytics.service";
import { FORM_TYPES } from "../../../evidence/actions/ActionOthers/actionOther.constants";

const IMPERS_LS_KEY = "impersonationStateFrom";
const IMPERS_DEFAULT_STATE = "farm.active.map";
const AUTH_URL = `${process.env.GATEWAY_URL}/authenticate`;
const SWITCH_AUTH_URL = `${process.env.GATEWAY_URL}/switch-authentication`;

/* @ngInject */
export default function Auth(
  BACKEND_OPTIONS,
  $http,
  $state,
  UserStorage,
  FarmsStorage,
  authService,
) {
  const service = {
    clearAccessToken,
    clearRefreshToken,
    refreshLogin,
    login,
    logout,
    impersActivate,
    impersDeactivate,
    isImpersActive,
    clearAllUserData,
  };

  return service;

  // ///////////////////////

  function refreshLogin() {
    const shouldUpdateUser = !UserStorage.hasUser();
    let respPromise = handleTokenResponse(doGetAccessToken());

    if (shouldUpdateUser) {
      respPromise = respPromise
        .then((token) => setupUser(token))
        .then((user) => updateIntercomUser(user));
    }

    return respPromise
      .then(() => confirmAuthentication())
      .catch(() => logout());
  }

  function login(credentials) {
    const data = {
      username: trim(credentials.username),
      password: trim(credentials.password),
      rememberMe: true, // this should be set from FE later on
    };

    return handleTokenResponse(doLoginPost(data))
      .then((token) => setupUser(token))
      .then((user) => updateIntercomUser(user))
      .then(() => confirmAuthentication());
  }

  function updateIntercomUser(user) {
    Intercom.restart(BACKEND_OPTIONS.intercom_app_id, {
      email: trim(user.email),
      user_id: user.id.toString(),
      name: `${trim(user.lastName)} ${trim(user.firstName)}`,
    });
  }

  function confirmAuthentication() {
    authService.loginConfirmed("success", (config) => {
      config.headers.Authorization = getAuthHeader();
      return config;
    });
  }

  // see the counterpart React implementation in auth.api.js#getAccessToken
  function doGetAccessToken() {
    let headers;

    // Send the old authorization in order to restore context of
    // the original and impersonated user on BE. We use a different header
    // from Authorization because the token could be already
    // expired (we only need it to restore the context, not for validation,
    // which is performed by the refresh token)
    if (JwtService.isImpersonatedToken()) {
      headers = {
        "X-Expired-Authorization": JwtService.getTokenValue(),
      };
    }

    return $http.get(AUTH_URL, {
      headers,
      ignoreAuthModule: true,
    });
  }

  function doLoginPost(credentials) {
    const headers = {
      "Content-Type": "application/json",
    };

    return $http.post(AUTH_URL, credentials, {
      headers,
      ignoreAuthModule: true,
    });
  }

  function doSwitchAuthPost(userId) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: getAuthHeader(),
    };

    return $http.post(SWITCH_AUTH_URL, userId, {
      headers,
      ignoreAuthModule: userId !== null,
    });
  }

  function doLogoutDelete(url) {
    return $http.delete(url);
  }

  function handleTokenResponse(promise) {
    return promise
      .then((response) => {
        const token = response.data;
        JwtService.storeToken(token);
        return token;
      })
      .catch((e) => {
        throw _.pick(e.data, [
          "status",
          "error",
          "message",
          "path",
          "timestamp",
        ]);
      });
  }

  function setupUser(token) {
    return UserStorage.setUser(token.principal.toLowerCase());
  }

  function clearAccessToken() {
    JwtService.removeToken();
    UserStorage.removeUser();
  }

  function clearRefreshToken() {
    return doLogoutDelete(AUTH_URL);
  }

  function clearUserCache() {
    setTimeout(() => resetFarmsCache(), 0);
    setTimeout(() => LocalStorage.removeFromLocalStorage(IMPERS_LS_KEY), 0);
  }

  function clearAllUserData() {
    clearAccessToken();
    clearRefreshToken();
    clearUserCache();
  }

  function logout() {
    clearAllUserData();
    $state.go("login");
  }

  function resetFarmsCache() {
    UiCache.deleteParams();

    const farms = FarmsStorage.getFarms();
    // removes saved eph state
    if (farms && Array.isArray(farms)) {
      farms.forEach((farm) => {
        localStorageService.remove(`state_eph_${farm.id}`);
        localStorageService.remove(`state_vrf_${farm.id}`);
        localStorageService.remove(`state_vrs_${farm.id}`);
        Object.keys(FORM_TYPES).forEach((formType) =>
          localStorageService.remove(`form_state_${formType}${farm.id}`),
        );
      });
    }
    FarmsStorage.removeFarms();
  }

  function getAuthHeader() {
    return `Bearer ${JwtService.getTokenValue()}`;
  }

  function isImpersActive() {
    return JwtService.isImpersonatedToken();
  }

  function impersActivate(
    farm,
    userId,
    custStateNameTo,
    custStateParamsTo = {},
  ) {
    Intercom.shutdown();
    Hotjar.shutdown();
    GoogleAnalytics.disable();
    return handleTokenResponse(doSwitchAuthPost(userId))
      .then((token) => setupUser(token))
      .then(() => {
        let state = custStateNameTo;
        if (!state) {
          state = $state.current.name;
          const regex = /(^farm.active\.)([^\.]*)/g;
          state = head(state.match(regex));

          // these are naughty hacks because in stores, we do not
          // follow hierarchic state structure, thus the states are
          if (state === "farm.active.stores") {
            state = "farm.active.stores.fertilizers";
          }

          if (state === "farm.active.irrigation") {
            state = "farm.active.irrigation.areas";
          }

          if (state === "farm.active.telematics") {
            state = "farm.active.telematics.drivers";
          }

          if (state === "farm.active.admin") {
            state = "farm.active.admin.sensors";
          }

          if (state === "farm.active.user") {
            state = "farm.active.user.integrations";
          }

          if (state === "farm.active.catalogues") {
            state = "farm.active.catalogues.seedsReact";
          }
        }

        LocalStorage.removeFromLocalStorage(IMPERS_LS_KEY);
        LocalStorage.saveToLocalStorage(
          {
            state: $state.current.name,
            params: $state.params,
          },
          IMPERS_LS_KEY,
        );

        return reloadState(state, {
          ...custStateParamsTo,
          farmId: farm.id,
          isImpersEnabled: true,
        });
      });
  }

  function impersDeactivate() {
    return handleTokenResponse(doSwitchAuthPost(null))
      .then((token) => setupUser(token))
      .then((user) => {
        const token = JwtService.readToken();

        const defaultStateFrom = {
          state: IMPERS_DEFAULT_STATE,
          params: {},
        };

        let impersonationStateFrom = null;
        try {
          impersonationStateFrom =
            LocalStorage.loadFromLocalStorage(IMPERS_LS_KEY);
        } catch (e) {}

        if (!impersonationStateFrom) {
          impersonationStateFrom = defaultStateFrom;
        }

        LocalStorage.removeFromLocalStorage(IMPERS_LS_KEY);

        const { state, params } = impersonationStateFrom;
        return reloadState(state, {
          ...params,
          farmId: token.user_farms[0],
          isImpersEnabled: true,
        }).then(() => {
          Intercom.boot(BACKEND_OPTIONS.intercom_app_id, {
            email: trim(user.email),
            user_id: user.id.toString(),
            name: `${trim(user.lastName)} ${trim(user.firstName)}`,
            hide_default_launcher: true,
          });
          Hotjar.boot();
          GoogleAnalytics.allow();
        });
      });
  }

  function reloadState(state, params) {
    try {
      return transitionTo(state, params).catch((e) =>
        transitionTo(IMPERS_DEFAULT_STATE, params),
      );
    } catch (e) {
      return Promise.resolve();
    }
  }

  function transitionTo(state, params = {}) {
    try {
      return $state.transitionTo(state, params, {
        reload: true,
        inherit: false,
        notify: true,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

function clearAccessToken() {
  JwtService.removeToken();
  LocalStorage.removeFromLocalStorage("user");
}

async function clearRefreshToken() {
  return axios.delete(AUTH_URL);
}

function clearUserCache() {
  UiCache.deleteParams();

  const farms = LocalStorage.loadFromLocalStorage("farms");
  if (farms && Array.isArray(farms)) {
    farms.forEach((farm) => {
      LocalStorage.removeFromLocalStorage(`state_eph_${farm.id}`);
      LocalStorage.removeFromLocalStorage(`state_vrf_${farm.id}`);
      LocalStorage.removeFromLocalStorage(`state_vrs_${farm.id}`);
      Object.keys(FORM_TYPES).forEach((formType) =>
        LocalStorage.removeFromLocalStorage(`form_state_${formType}${farm.id}`),
      );
    });
  }
  LocalStorage.removeFromLocalStorage("farms");
}

const logout = async () => {
  clearAccessToken();
  await clearRefreshToken();
  clearUserCache();
  window.location.assign("/login");
};

function impersonate(farm, userId) {
  Intercom.shutdown();
  Hotjar.shutdown();
  GoogleAnalytics.disable();
  return handleTokenResponse(doSwitchAuthPost(userId))
    .then((token) => setupUser(token))
    .then(() => {
      LocalStorage.removeFromLocalStorage(IMPERS_LS_KEY);

      window.location.assign(
        window.location.pathname.split("/").with(2, farm.id).join("/"),
      );
    });
}

function handleTokenResponse(promise) {
  return promise
    .then((response) => {
      const token = response.data;
      JwtService.storeToken(token);
      return token;
    })
    .catch((e) => {
      throw _.pick(e.data, ["status", "error", "message", "path", "timestamp"]);
    });
}

function doSwitchAuthPost(userId) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: getAuthHeader(),
  };

  return axios.post(SWITCH_AUTH_URL, userId, {
    headers,
    ignoreAuthModule: userId !== null,
  });
}

function getAuthHeader() {
  return `Bearer ${JwtService.getTokenValue()}`;
}

function setupUser(token) {
  return setUser(token.principal.toLowerCase());
}

async function setUser(username) {
  return axios
    .get(`${process.env.GATEWAY_URL}/users/${username}`)
    .then((userData) => {
      LocalStorage.saveToLocalStorage(userData.data, "user");
      return userData;
    });
}

function deimpersonate() {
  return handleTokenResponse(doSwitchAuthPost(null))
    .then((token) => setupUser(token))
    .then(() => {
      const token = JwtService.readToken();
      const farm = token.user_farms[0];
      LocalStorage.removeFromLocalStorage(IMPERS_LS_KEY);

      if (window.location.pathname.includes("sensors")) {
        redirectToSensors(farm);
      } else {
        redirectToFarm(farm);
      }
    });
}

function isImpersonated() {
  return JwtService.isImpersonatedToken();
}

const redirectToSensors = (farm) => {
  const newLocation = [
    ...window.location.pathname.split("/").with(2, farm).slice(0, 3),
    "admin",
    "sensors",
  ].join("/");

  window.location.assign(newLocation);
};

const redirectToFarm = (farm) => {
  const newLocation = window.location.pathname
    .split("/")
    .with(2, farm)
    .join("/");

  window.location.assign(newLocation);
};

export { logout, impersonate, deimpersonate, isImpersonated };
