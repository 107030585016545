import { queryOptions } from "@tanstack/react-query";

import {
  getParcelSeasons,
  GetParcelSeasonsParams,
  getV2SeasonBiomonitoring,
  GetV2SeasonBiomonitoringParams,
  getV2SeasonInternalBiomonitoring,
  GetV2SeasonInternalBiomonitoringParams,
} from "../../../generated/api/satellite";
import { staleTime } from "../../../shared/query-client";

const biomonitoringKey = ["satellite", "biomonitoring"];

const cropIntervalsQuery = (
  parcelId: string,
  payload: GetParcelSeasonsParams,
  isInit: boolean,
) =>
  queryOptions({
    queryKey: [
      ...biomonitoringKey,
      "cropIntervals",
      parcelId,
      ...Object.values(payload),
    ],
    queryFn: ({ signal }) => getParcelSeasons(parcelId, payload, { signal }),
    staleTime: staleTime.Infinity,
    enabled: isInit,
    placeholderData: (prev) => prev,
  });

const biomonitoringDataQuery = (
  parcelId: string,
  payload: GetV2SeasonBiomonitoringParams,
  isInit: boolean,
) =>
  queryOptions({
    queryKey: [
      ...biomonitoringKey,
      "data",
      parcelId,
      ...Object.values(payload),
    ],
    queryFn: ({ signal }) =>
      getV2SeasonBiomonitoring(parcelId, payload, { signal }),
    staleTime: staleTime.Infinity,
    enabled: isInit,
  });

const biomonitoringDataSaviQuery = (
  parcelId: string,
  payload: GetV2SeasonInternalBiomonitoringParams,
  isInit: boolean,
) =>
  queryOptions({
    queryKey: [
      ...biomonitoringKey,
      "data",
      parcelId,
      ...Object.values(payload),
    ],
    queryFn: ({ signal }) =>
      getV2SeasonInternalBiomonitoring(parcelId, payload, { signal }),
    staleTime: staleTime.Infinity,
    enabled: isInit,
  });

export {
  cropIntervalsQuery,
  biomonitoringDataQuery,
  biomonitoringDataSaviQuery,
};
