import React from "react";

import moment from "moment";
import { ProductFruits } from "react-product-fruits";

import { LANGUAGE_ID } from "../../lang/lang.constants";

import { getShortDateString } from "../../misc/timeHelpers";

type Props = {
  account?: {
    authorities: string[];
    createdDate: number;
    firstName: string;
    lastName: string;
    login: string;
    email: string;
  };
  farm: {
    name: string;
    customer: {
      countryCode: string;
    };
    id: string;
  };
  langId: LANGUAGE_ID;
  projectId: string;
};

const ProductFruitsProvider = ({
  account,
  children,
  farm,
  langId,
  projectId,
}: React.PropsWithChildren<Props>) => {
  if (!account || !projectId) {
    return children;
  }
  const createdDate = account.createdDate
    ? getShortDateString(moment(account.createdDate * 1000))
    : undefined;
  const isAdmin = account.authorities.includes("ROLE_ADMIN");
  const userInfo = {
    username: account?.login,
    email: account.email,
    firstName: account.firstName,
    lastName: account.lastName,
    role: isAdmin ? "admin" : "user",
    signUpAt: createdDate,
    props: {
      farmId: farm.id,
      farmName: farm.name,
      farmCountryCode: farm.customer.countryCode,
      userLanguage: langId === "cs-CZ" ? "cs" : "en",
    },
  };

  return (
    <>
      <ProductFruits
        language={langId === "cs-CZ" ? "cs" : "en"}
        user={userInfo}
        workspaceCode={projectId}
      />
      {children}
    </>
  );
};

export default ProductFruitsProvider;
