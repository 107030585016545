import React, { Fragment } from "react";

import CfFormattedNumber from "../../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import { useToggle } from "../../../../../../shared/hooks/useToggle";

import SubtractableAreaDialog from "./SubtractableAreaDialog";

type Props = {
  editing?: boolean;
  formName: string;
  index: number;
  parcel: {
    area: number;
    restrictedArea?: number;
  };
  subtractableAreasIds?: number[];
};

const SubtractableAreaLink = ({
  editing = false,
  formName,
  index,
  parcel,
  subtractableAreasIds,
}: Props) => {
  const { on: isOpen, setOff: onClose, setOn: onOpen } = useToggle();

  return (
    <Fragment>
      <a data-test="subtract-area-link" href="#" onClick={onOpen}>
        <CfFormattedNumber
          value={parcel.area - (parcel.restrictedArea || 0)}
        />{" "}
      </a>
      {" / "}
      <CfFormattedNumber value={parcel.area} />
      <SubtractableAreaDialog
        editing={editing}
        formName={formName}
        handleClose={onClose}
        index={index}
        onAccept={onClose}
        opened={isOpen}
        parcel={parcel}
        subtractableAreasIds={subtractableAreasIds}
      />
    </Fragment>
  );
};

export { SubtractableAreaLink };
