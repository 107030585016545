import React from "react";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { ListItem } from "../../../../../shared/components/common/SelectionItem/ListItem";
import Localization from "../../../../../shared/services/Localization.service";
import { COLOR_FONT } from "../../../../../theme";
import { AnyTodo } from "../../../../../types";

type Props = {
  items: AnyTodo[];
  onItemRemove: (val: number) => void;
  isEditing: boolean;
  dosePerHa: number;
};

export const PestsList = ({
  dosePerHa,
  isEditing,
  items = [],
  onItemRemove,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.activeSubstances} id="active-substances-list">
      {items.length > 0 &&
        items.map((field, index) => (
          <div key={index}>
            <ListItem
              isEditing={isEditing}
              key={field.organism?.id}
              header={
                <div className={classes.row}>
                  <div className={classes.name} data-test="item-heading">
                    {field.name}
                  </div>
                </div>
              }
              onRemoveItem={() => {
                onItemRemove(index);
              }}
              variant={
                !field?.isAllowedForCrop ||
                field.maxDose <
                  Localization.parseNumberFromLocalizedString(
                    String(dosePerHa),
                  ) ||
                Localization.parseNumberFromLocalizedString(
                  String(dosePerHa),
                ) === 0 ||
                !Localization.parseNumberFromLocalizedString(String(dosePerHa))
                  ? "warning"
                  : undefined
              }
            />
            {!field?.isAllowedForCrop && (
              <div
                className={classes.warningMessage}
                data-test="pest-warning-noeffect"
              >
                <FormattedMessage id="Por.pest.noEffect.warning" />
              </div>
            )}
            {(field.maxDose <
              Localization.parseNumberFromLocalizedString(String(dosePerHa)) ||
              Localization.parseNumberFromLocalizedString(String(dosePerHa)) ===
                0 ||
              !Localization.parseNumberFromLocalizedString(
                String(dosePerHa),
              )) && (
              <div
                className={classes.warningMessage}
                data-test="pest-warning-info"
              >
                <FormattedMessage
                  id="Por.pest.minMaxDose.warning"
                  values={{
                    min: field.minDose,
                    max: field.maxDose,
                    doseUnit: field.recommendedDoseUnit,
                  }}
                />
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  activeSubstances: {
    marginTop: 25,
    marginBottom: 35,
  },
  name: {
    fontSize: 16,
    marginRight: 10,
    color: COLOR_FONT.main,
    [theme.breakpoints.down("md")]: {
      marginBottom: 10,
    },
  },
  warningMessage: {
    fontSize: "12px",
    marginTop: "-5px",
    color: "#B28500",
  },
  detailContent: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "10px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
    },
  },
  row: {
    width: "100%",
  },
  warningBackground: {
    backgroundColor: "red",
  },
  label: {
    color: theme.palette.grey[400],
  },
  integratedProduction: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: 20,
  },
  integratedProductionItem: {
    display: "flex",
    alignItems: "center",
    marginRight: 20,
    fontSize: 14,
  },
  field: {
    width: "100%",
  },
}));
