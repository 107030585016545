import React from "react";

import moment from "moment";
import { createRoot } from "react-dom/client";

import reducer from "../../reducers/signup.reducer";

import CfApp from "../../shared/containers/CfApp/CfApp";
import Localization from "../../shared/services/Localization.service";

import SignupWrapper from "./containers/SignupWrapper/SignupWrapper";

SignupController.$inject = [
  "$element",
  "$state",
  "$stateParams",
  "$scope",
  "$rootScope",
  "BACKEND_OPTIONS",
  "ENVIRONMENT",
  "Auth",
];

export default function SignupController(
  $element,
  $state,
  $stateParams,
  $scope,
  $rootScope,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Auth,
) {
  let root;
  const props = {
    ngRedirectToLogin,
    ngRedirectToFarm,
    ngLogin,
    ngRefreshLogin,
    ngLogoutNoRedirect,
    reducer,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    currState: $state.current.name,
    apiProps: {
      beOpts: BACKEND_OPTIONS,
      displayErrorModal: false,
      logout: Auth.logout,
    },
  };

  const unlistenTranslateChange = $rootScope.$on(
    "$translateChangeSuccess",
    () => {
      renderWithLang();
    },
  );

  $scope.$on("$destroy", () => {
    unlistenTranslateChange();
    root.unmount();
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  function ngRedirectToLogin() {
    $state.go("login");
  }

  function ngRedirectToFarm() {
    $state.go("farm");
  }

  function ngLogin(credentials) {
    return Auth.login(credentials);
  }

  function ngRefreshLogin() {
    return Auth.refreshLogin();
  }

  function ngLogoutNoRedirect() {
    return Auth.clearAllUserData(false);
  }

  function renderWithLang() {
    const langId = Localization.getLang();
    moment.locale(langId);
    render();
  }

  function render() {
    $element[0].click();
    root = createRoot($element[0]);
    root.render(<CfApp {...props} render={(p) => <SignupWrapper {...p} />} />);
  }
}
