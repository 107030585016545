import React, { useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { isNil } from "lodash";
import { useSelector } from "react-redux";

import { getIndex } from "../../selectors/monitoring.selectors";

import {
  BiomonitoringIntervalResponse,
  PrecisionFarmingProduct,
  SeasonWithCropTo,
} from "../../../../generated/api/satellite";
import { useFarmIds } from "../../../../shared/api/client.utils";
import {
  disableHTMLCanvasImageSmoothing,
  enableHTMLCanvasImageSmoothing,
} from "../../../../shared/misc/helpers";
import { BioMonitoringHistoryInfoDialog } from "../../components/BioMonitoring/BioMonitoringHistoryInfoDialog";
import {
  biomonitoringDataQuery,
  cropIntervalsQuery,
} from "../Biomonitoring.api";
import { ChartDataOrMonitoringDataV2 } from "../BioMonitoringV2";

import { MonitorIntervalType } from "./BiomonitoringWrapper/BiomonitoringWrapperV2";
import { isPlanetIndexType } from "./BiomonitoringWrapper/helpers";
import { IndexTypeDataV2Type } from "./types";
import { useLocking } from "./useLocking";

type Props = {
  selectedInterval?: MonitorIntervalType;
  parcelId: string;
  seasonId?: string;
  setCropIntervals: (val: SeasonWithCropTo[]) => void;
  setCropIntervalsIsFetching: (val: boolean) => void;
};

const useGisatBiomonitoringV2 = ({
  parcelId,
  seasonId,
  selectedInterval,
  setCropIntervals,
  setCropIntervalsIsFetching,
}: Props) => {
  const [isInit, setIsInit] = useState(false);
  const index = useSelector(getIndex);
  const dateFrom = selectedInterval?.dateFrom;

  const farmIds = useFarmIds();
  const biomonitoringData = useQuery(
    biomonitoringDataQuery(
      parcelId,
      {
        farmIds: farmIds.farmIds,
        type: index,
        "season-id": seasonId,
      },
      isInit && !isNil(dateFrom),
    ),
  );

  const data = biomonitoringData.data?.data ?? [];

  const cropIntervals = useQuery(
    cropIntervalsQuery(
      parcelId,
      {
        farmIds: farmIds.farmIds,
        product: PrecisionFarmingProduct.GISAT_BIOPHYSICAL_MONITORING,
      },
      !isInit,
    ),
  );

  const cropIntervalsData = cropIntervals.data?.data ?? [];

  const cropIntervalsIsFetching =
    !cropIntervals.isSuccess && !cropIntervals.isError;

  const { isInit: lockingIsInit } = useLocking({
    isInitCustomCondition: !isPlanetIndexType(index),
    cropIntervals: cropIntervalsData,
    selectedIntervalId: selectedInterval?.selectedIntervalId,
    isFetchingCropIntervals: cropIntervalsIsFetching,
    cropIntervalsFetched: cropIntervals.isSuccess,
  });

  useEffect(() => {
    setIsInit(lockingIsInit);
  }, [lockingIsInit]);

  useEffect(() => {
    setCropIntervalsIsFetching(cropIntervalsIsFetching);
  }, [cropIntervalsIsFetching, setCropIntervalsIsFetching]);

  useEffect(() => {
    if (!cropIntervalsIsFetching && !isInit) {
      setCropIntervals(cropIntervalsData);
    }
  }, [cropIntervalsData, cropIntervalsIsFetching, isInit, setCropIntervals]);

  useEffect(() => {
    if (!isInit) {
      disableHTMLCanvasImageSmoothing();
    }
    return () => {
      enableHTMLCanvasImageSmoothing();
    };
  }, [isInit]);

  // const overallImagesError =
  //   BioMonitoringImageErrorService.getOverallImagesError(data, index);
  // const cropHarvestedError =
  //   BioMonitoringImageErrorService.checkCropHarvestedError(data);
  const lastImageDateTo = "TODO-VALIDITY";

  const infoDialogContent = (
    <BioMonitoringHistoryInfoDialog.Root index={index} />
  );

  const resData = data
    .filter((item: BiomonitoringIntervalResponse) => item?.snapshots?.length)
    .map((item) => ({
      crop: item.crop,
      dateFrom: item.dateFrom,
      dateTo: item.dateTo,
      imgExtent: item.snapshots[0].imgExtent,
      zones: item.snapshots[0].zones,
      status: item.status,
      value: item.snapshots[0].value,
      imgPath: item.snapshots[0].imgPath,
      indexType: item.snapshots[0].indexType,
    }));

  const graphData = resData.slice().reverse() as ChartDataOrMonitoringDataV2[];

  const isFetching =
    (!biomonitoringData.isSuccess && !biomonitoringData.isError) ||
    biomonitoringData.isFetching ||
    biomonitoringData.isPending ||
    biomonitoringData.isLoading ||
    biomonitoringData.isPlaceholderData ||
    biomonitoringData.isRefetching ||
    biomonitoringData.fetchStatus === "fetching" ||
    cropIntervalsIsFetching;

  return {
    isFetching,
    overallImagesError: null,
    cropHarvestedError: null,
    lastImageDateTo,
    data: resData,
    infoDialogContent,
    graphData,
  } as IndexTypeDataV2Type;
};

export { useGisatBiomonitoringV2 };
