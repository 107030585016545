import React, { useContext } from "react";

import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { useNitrateBalanceMutation } from "../../../../shared/api/agroevidence/reports/reports.api";
import { useFarmIds } from "../../../../shared/api/client.utils";
import PageHeading from "../../../../shared/components/common/PageHeading/PageHeading";
import { SnackbarContext } from "../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { useToggle } from "../../../../shared/hooks/useToggle";
import FileService from "../../../../shared/services/File.service";
import { AnyTodo } from "../../../../types";

import { NitrogenBalanceReportDialog } from "./NitrogenBalanceReportDialog";

const NitrogenBalanceReport = () => {
  const classes = useStyles();
  const farmIds = useFarmIds();

  const showSnackbar = useContext(SnackbarContext);

  const {
    on: showDialog,
    setOff: handleDialogClose,
    setOn: handleDialogOpen,
  } = useToggle();

  const { isPending, mutate } = useNitrateBalanceMutation();

  const handleDownload = async (year: string) => {
    const params = [
      {
        year,
        ...farmIds,
      },
    ] as AnyTodo;

    mutate(params, {
      onSuccess: (data) => {
        FileService.processFileResponseTanStack(data);
      },
      onError: () => {
        showSnackbar({
          message: (
            <FormattedMessage id="Reports.nitrogenBalance.errorMessage" />
          ),
          isError: true,
        });
      },
    });
  };

  return (
    <>
      {showDialog && (
        <NitrogenBalanceReportDialog
          isLoading={isPending}
          onAccept={handleDownload}
          onClose={handleDialogClose}
          showDialog={showDialog}
        />
      )}
      <span className={classes.heading}>
        <PageHeading
          dataTest="report=csv-heading"
          translationId="Reports.nitrogenBalance.heading"
        />
      </span>
      <Grid className={classes.info} container justifyContent="center">
        <Grid item sm={2} xs={1} />
        <Grid item sm={8} xs={12}>
          <FormattedMessage
            id="Reports.nitrogenBalance.Info"
            values={{
              link: (
                <a
                  href="https://www.carc.cz/2024/08/05/bilance-n-za-hospodarsky-rok-hnojeni-2023-2024/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  CARC
                </a>
              ),
              br: <br />,
              b: (chunks: string) => <b>{chunks}</b>,
            }}
          />
        </Grid>
        <Grid item sm={2} xs={1} />
      </Grid>
      <div className={classes.button}>
        <Button
          color="primary"
          disabled={isPending}
          id="nitrogen-balance"
          onClick={handleDialogOpen}
          variant="contained"
        >
          <FormattedMessage id="Reports.cropRotation.button" />
          {isPending && (
            <CircularProgress className={classes.progress} size={21} />
          )}
        </Button>
      </div>
    </>
  );
};

export { NitrogenBalanceReport };

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    marginBottom: 10,
    fontSize: 24,
    fontWeight: 500,
  },
  info: {
    fontSize: 13,
    color: theme.palette.grey[500],
    marginBottom: 20,
    textAlign: "center",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 30,
  },
  progress: {
    marginLeft: 10,
  },
}));
