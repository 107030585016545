import React from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Theme } from "@mui/material";
import Popover from "@mui/material/Popover";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";
import Draggable from "react-draggable";
import { FormattedMessage } from "react-intl";

import impersonateFarmSvg from "../../assets/img/impersonate-farm.svg";
import {
  deimpersonate,
  impersonate,
  isImpersonated,
} from "../../common/services/auth/Auth.service";
import usePopover from "../../shared/hooks/usePopover";
import { useToggle } from "../../shared/hooks/useToggle";
import { useIsMobile } from "../../shared/hooks/useWidth";
import AdminFarmSelector from "../shared/components/AdminFarmSelector/AdminFarmSelector";

import { farmsQuery, farmUsersQuery } from "./impersonation.api";
import { ImpersonationButton } from "./ImpersonationButton";

type Props = {
  farm: { id: string; name: string };
  toggle: () => void;
};

const ImpersonationPanel = (props: Props) => {
  const [search, setSearch] = React.useState("");
  const impersonated = isImpersonated();
  const [currentFarm, setCurrentFarm] = React.useState<
    undefined | typeof props.farm
  >();
  const dragging = useToggle();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const popover = usePopover();
  const allFarms = useQuery(farmsQuery({ search }));
  const farmUsers = useQuery({
    // @ts-expect-error this hardly ever will be null since `enabled` prop is used
    ...farmUsersQuery({ farmId: currentFarm?.id }),
    enabled: !!currentFarm,
  });

  React.useEffect(() => {
    if (impersonated) {
      setCurrentFarm(props.farm);
    }
  }, [impersonated, props.farm]);

  React.useEffect(() => {
    if (impersonated) {
      return;
    }

    if (!currentFarm) {
      return;
    }

    const user = farmUsers.data?.data[0];
    if (!user) {
      return;
    }
    popover.handlePopoverClose();
    impersonate(currentFarm, user.userId);
  }, [impersonated, currentFarm, farmUsers.data?.data, popover]);

  const handleStartSelectingFarms = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (dragging.on) {
      dragging.setOff();
      return;
    }

    if (impersonated) {
      return;
    }

    popover.handlePopoverOpen(e);
  };

  const handleClearSelectedFarm = () => {
    setCurrentFarm(undefined);
    popover.handlePopoverClose();
    deimpersonate();
  };

  const handleOnSuggestionClear = () => {
    popover.handlePopoverClose();
  };

  const handleOnSuggestionSelect = (farm: Props["farm"]) => {
    setCurrentFarm(farm);
    popover.handlePopoverClose();
  };

  let Buttons = null;

  if (!impersonated) {
    Buttons = (
      <>
        <ImpersonationButton
          callback={handleStartSelectingFarms}
          testId="select-farm"
          classes={{
            root: classes.buttonFarmSelector,
          }}
        >
          <img
            alt="Impersonate farm"
            className={classes.image}
            src={impersonateFarmSvg}
          />
          <FormattedMessage id="Impersonation.impersonate-farm" />
          <ArrowDropDownIcon className={classes.rightIcon} />
        </ImpersonationButton>
        <ImpersonationButton
          callback={props.toggle}
          classes={{}}
          testId="close"
        >
          <ExitToAppIcon />
        </ImpersonationButton>
      </>
    );
  } else {
    Buttons = (
      <>
        <ImpersonationButton
          callback={() => {}}
          classes={{ root: classes.buttonCancelImpersonation }}
          disabled
          farm={currentFarm}
          testId=""
        >
          <span>{currentFarm?.name}</span>
        </ImpersonationButton>
        <ImpersonationButton
          callback={handleClearSelectedFarm}
          farm={currentFarm}
          testId="cancel"
          classes={{
            root: classes.buttonCancelImpersonation,
          }}
        >
          <ClearIcon
            className={`${classes.clearIcon} ${
              currentFarm ? classes.clearIconActive : ""
            }`}
          />
        </ImpersonationButton>
      </>
    );
  }

  const jsx = (
    <div className={classes.panel}>
      {Buttons}

      <Popover
        anchorEl={popover.anchorEl}
        classes={{ paper: classes.popoverPaper }}
        className={classes.modalRoot}
        onClose={handleOnSuggestionClear}
        open={popover.isOpen}
        PaperProps={{
          "data-test": "farm-list",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        <AdminFarmSelector
          getFarmsByName={(search: string) => setSearch(search)}
          isFetching={allFarms.status === "pending"}
          onSuggestionClear={handleOnSuggestionClear}
          onSuggestionSelect={handleOnSuggestionSelect}
          suggestions={allFarms.data?.data ?? []}
        />
      </Popover>
    </div>
  );

  if (isMobile) {
    return jsx;
  }

  return (
    <Draggable bounds="html" disabled={popover.isOpen} onDrag={dragging.setOn}>
      {jsx}
    </Draggable>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  panel: {
    position: "fixed",
    bottom: 15,
    right: 15,
    zIndex: 5147483000,
    background: theme.palette.background.paper,
    boxShadow:
      "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    borderRadius: 2,
  },
  image: {
    marginRight: theme.spacing(1),
    position: "relative",
    top: "-2px",
  },
  buttonFarmSelector: {
    borderRadius: "2px 0px 0px 2px",
  },
  buttonCancelImpersonation: {
    borderRadius: "0px 2px 2px 0px",
    minWidth: 56,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    position: "relative",
    top: -1,
  },
  clearIcon: {
    color: theme.palette.grey[400],
  },
  clearIconActive: {
    color: theme.palette.background.paper,
  },
  popoverPaper: {
    width: 350,
    height: 350,
    overflow: "hidden",
  },
  modalRoot: {
    zIndex: 5147483002,
  },
}));

export { ImpersonationPanel };
